import React from 'react';
import "./loading.css"
import { appConfig } from '../../../utils/app.config';

interface LoadingProps {
    message?: string;
    height?: string;
}

const Loading: React.FC<LoadingProps> = ({ message = 'Loading...', height = "85vh" }) => {

    return (
        <>
            <div className='loading-pop-up' style={{ height: `${height}` }}>
                <div className='loading-pop-up-container'>
                    <div className="loading-pop-up-icon">
                        <img src={appConfig.imagesUrl + '/desktop/loading.gif'}></img>
                    </div>
                    <div className="loading-pop-up-text">
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loading;
