import { Chip, Button } from "@mui/material";
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface FormType {
  formType: string;
}

const Form: React.FC<FormType> = ({ formType }) => {
  const redirectUrl: string = useSelector(
    (state: any) => state.headers.redirectUrl
  );

  interface fileFormatProps {
    type: string;
    sysId: string;
    enData: string;
    name: string;
  }

  type IForm = {
    affectedApplication: string;
    shortDescription: string;
  };

  const [fileContent, setFileContent] = useState("");
  const [fileUpload, setFileUpload] = useState<fileFormatProps[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>();

  const onSubmit: SubmitHandler<IForm> = (data: any) => {
    console.log("form data", data);
  };

  const fileselect = () => {
    document.getElementById("file")?.click();
  };

  const FileChangeEvent = (e: any) => {
    console.log("event", e.target.files[0]);

    const files = e.target.files;

    // if(files.length>2){

    // }
    // else{
    for (var i = 0; i < files.length; i++) {
      console.log("for loop");
      const reader = new FileReader();
      let CurrentFile = files[i];
      console.log(CurrentFile);
      reader.readAsDataURL(files[i]);

      reader.onload = (e) => {
        let fileType = CurrentFile.type;
        let fileName = CurrentFile.name;
        let base64Img = reader?.result as string;

        let ab = base64Img?.split(",");
        console.log("ab", ab);
        base64Img = ab[1];
        console.log(base64Img);

        fileFormat(fileType, "", fileName, base64Img);
        setFileContent(CurrentFile.name);
      };
    }

    // }
  };

  const fileFormat = (type: any, sysId: any, name: any, enData: any) => {
    const data: fileFormatProps = {
      type: type,
      sysId: sysId,
      enData: enData,
      name: name,
    };

    setFileUpload((prevFiles) => [...prevFiles, data]);

    console.log("file upload", fileUpload);
  };

  const removeFile = (file: any) => {
    const index = fileUpload.indexOf(file);
    if (index > -1) {
      const updatedFiles = [...fileUpload];
      updatedFiles.splice(index, 1);
      setFileUpload(updatedFiles);
    }
  };

  return (
    <>
      {formType !== 'Catalog' ? (
        <form
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="textboxt1">
            <div className="t1">
              <p>
                Affected Application <b style={{ color: "red" }}>*</b>
              </p>
            </div>
            <input
              type="text"
              {...register("affectedApplication", {
                required: { value: true, message: "Required Field" },
              })}
              className="options1"
            />
            {errors.affectedApplication && (
              <div className="error2">{errors.affectedApplication.message}</div>
            )}
          </div>
          <div className="textboxt2">
            <div className="description">
              <p>
                Short Description <b style={{ color: "red" }}>*</b>
              </p>
            </div>
            <textarea
              className="options2"
              {...register("shortDescription", {
                required: {
                  value: true,
                  message: "Short Description is required",
                },
              })}
            ></textarea>

            {errors.shortDescription && (
              <div style={{ marginTop: "-28px" }} className="error2">
                {errors.shortDescription.message}
              </div>
            )}
          </div>

          <div className="attachment">
            <p className="attachmentheading">Attachment</p>
            <div className="info">
              Please refrain from uploading the personal data.
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {fileUpload?.length > 0 ? (
                <>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    {fileUpload.map((file) => (
                      <Chip
                        color="primary"
                        label={file?.name}
                        onDelete={() => removeFile(file)}
                        icon={
                          <i
                            style={{ paddingLeft: "4px" }}
                            className="bi bi-image"
                          ></i>
                        }
                      />

                      //   <div className="wrap">
                      //     <div className="wrapper">
                      //       <div className="image"></div>
                      //       <div className="text" id="textname">
                      //         {file?.name}
                      //       </div>
                      //     </div>
                      //     <div
                      //       className="close2"
                      //       id="close2"
                      //       onClick={() => removeFile(file)}
                      //     ></div>
                      //   </div>
                    ))}
                  </div>
                </>
              ) : (
                <></>
              )}
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .xls, .xlsx, .docx"
                id="file"
                value=""
                style={{ display: "none" }}
                multiple
                onChange={FileChangeEvent}
              />
              <div>
                <button
                type='button'
                  className="attachbutton"
                  id="attachbtn"
                  onClick={fileselect}
                >
                  <div className="attachtext1">
                    <p style={{ margin: 0 }}>+ Attach a file</p>
                  </div>
                  <div className="attachtext2">
                    <p>200 mb file</p>
                  </div>
                </button>
              </div>

              {/* <div class="error3" id="error3" style="display: none;" >
                        {{i18nTexts.error}}</div> */}
            </div>
          </div>

          <div className="submit">
            <button
            type="submit"
             
              id="submit"
              className="btn btn-primary"
            >
              <div className="wrapper">
                <div className="okayButton"></div>
                Submit
              </div>
            </button>
          </div>

          <Link to={`${redirectUrl}/desktop`} style={{textDecoration: 'none'}} className="submit">
            <button
              // variant="contained"
              className="btn btn-light"
              // style={{ margin: 0 }}
              id="cancel"
            >
              Cancel
            </button>
          </Link>
        </form>
      ) : (
        <form
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          Catalog form layout will be here
        </form>
      )}
    </>
  );
};

export default Form;
