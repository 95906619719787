import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import appStore from './utils/redux-store/appStore';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/language-translator/i18n';
console.warn = () => { }; // Suppress warnings (development only)

const RootApp = () => {
  const [theme, colorMode] = useMode(); // Use the custom hook to get theme and toggle function

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          {/* <CssBaseline /> Ensures consistent styling across themes */}
          <Router basename="/ui">
            <App />
          </Router>
        </I18nextProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={appStore}>
        <RootApp />
      </Provider>
    </React.StrictMode>
  );
} else {
  console.error('Root element not found');
}
