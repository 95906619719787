import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { MenuLayout } from '../../../application/layout/menu.layout';
import BotProfiles from '../../../modules/chat-multi/pages/BotProfiles';
import SomeComponent from '../../pages/SomeComponent';
import { GenAIRoutes } from '../../../modules/gen-ai/genai.router';
import { GenAIStudioRoutes } from '../../../modules/gen-ai-studio/genai.studio.router';
import PrivacyPolicy from '../../commons/privacy-policy/privacy-policy';
import CoreRoutes from '../../../modules/core/core.router';
import ChatRoutes from '../../../modules/chat/chat.routes';
import EngineerAssist from '../../../modules/apps/engineer-assist/engineer-assist';
import UpdateHeaders from '../../commons/update-headers/update.headers';
import ResetPassword from '../../commons/reset-password/reset.password';
import UserProfile from '../../commons/UserProfile/UserProfile';
import UserInsight from '../../../modules/apps/user-insight/user-insight';
import DesktopRoutes from '../../../modules/apps/desktop-app/desktop.app.router';

const MenuRoutes: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(true);
    return (
        <div className={`app ${menuOpen ? 'menu-open' : 'menu-closed'}`}>
            <Routes>
                <Route path="/" element={<><MenuLayout menuOpen={menuOpen} setMenuOpen={setMenuOpen} /> <UpdateHeaders redirectUrl="/mn" headersDynamicButtons={[]} userProfileItems={[]} defaultUserProfileItemsOverride={[]} /></>}>
                    <Route index element={<CoreRoutes />} />
                    <Route path="/*" element={<CoreRoutes />} />
                    <Route path="genai/*" element={<GenAIRoutes />} />
                    <Route path="chat/*" element={<ChatRoutes />} />
                    <Route path="desktop/*" element={<DesktopRoutes redirectUrl='/mn' />} />
                    <Route path="bot-profiles" element={<BotProfiles />} />
                    <Route path="testerror" element={<SomeComponent />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/engineer-assist" element={<EngineerAssist />} />
                    <Route path='/user-profile' element={<UserProfile />} />
                    <Route path='/reset-password' element={<ResetPassword />} />
                    <Route path="/user-insight" element={<UserInsight />} />
                </Route>
            </Routes>
        </div>
    );
};

// Home component for /user
const CoreHome: React.FC = () => {
    return (
        <div>
            <h1>Core Home</h1>
        </div>
    );
};

export default MenuRoutes;
