import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useSelector } from 'react-redux';
import { Link, redirect } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import "./AppsList.css"

interface AppsListProps {
    redirectUrl?: string
}

const AppsList: React.FC<AppsListProps> = ({ redirectUrl }) => {
    const homeAppList = useSelector((store: any) => store.desktopApps.desktopApps);
    const isMobile = useMediaQuery('(max-width: 575px)');

    const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${isMobile ? '300px' : '500px'};  /* Conditional width for mobile and laptop */
    `;

    const AppLogo = styled.div`
        width: ${isMobile ? '40px' : '45px'};
        height: ${isMobile ? '40px' : '45px'};
        background-size: cover;
        background-position: center;
        margin-bottom: 5px;
    `;


    let appsList = homeAppList;
    if (appsList.length === 0) {
        return <p>No Apps Found</p>;
    }

    return (
        <Container>
            <MarqueeWrapper>
                <MarqueeGroup>
                    {appsList.map((app: any, index: number) => (
                        <AppItem key={index} className='hover_scale_appslist'>
                            <AppLogoContainer>
                                {(() => {
                                    let appLogoUrl = '';
                                    switch (app.name) {
                                        case 'Microsoft Teams':
                                        case 'Microsoft Teams Update':
                                            appLogoUrl = '/ui/static/img/desktop/apps/teams.png'
                                            break;
                                        case 'Google Chrome':
                                        case 'Google Chrome Installer':
                                            appLogoUrl = '/ui/static/img/desktop/apps/chrome.png'
                                            break;
                                        case 'Office':
                                            appLogoUrl = '/ui/static/img/desktop/apps/office.png'
                                            break;
                                        case 'Microsoft Outlook':
                                            appLogoUrl = '/ui/static/img/desktop/apps/outlook.png'
                                            break;
                                        case 'Snow':
                                            appLogoUrl = '/ui/static/img/desktop/apps/snow.png'
                                            break;
                                        case 'Postman':
                                            appLogoUrl = '/ui/static/img/desktop/apps/postman.png'
                                            break;
                                        case 'Microsoft Edge':
                                        case 'Microsoft Edge Installer':
                                        case 'Microsoft Edge Update':
                                            appLogoUrl = '/ui/static/img/desktop/apps/edge.jpg'
                                            break;
                                        case 'Vscode':
                                            appLogoUrl = '/ui/static/img/desktop/apps/vscode.png'
                                            break;
                                        case 'The OpenSSL Toolkit':
                                            appLogoUrl = '/ui/static/img/desktop/apps/ssl.png'
                                            break;
                                        case 'Nexthink Finder':
                                            appLogoUrl = '/ui/static/img/desktop/apps/Nexthink1.webp'
                                            break;
                                        case 'Windows Search':
                                            appLogoUrl = '/ui/static/img/desktop/apps/windows_search.png'
                                            break;
                                        case 'Azure Log Analytics':
                                            appLogoUrl = '/ui/static/img/desktop/apps/azureLogs.png'
                                            break;
                                        case 'Cisco Webex Meeting':
                                            appLogoUrl = '/ui/static/img/desktop/apps/cisco.PNG'
                                            break;
                                        case 'Xbox Game Bar':
                                            appLogoUrl = '/ui/static/img/desktop/apps/xbox.webp'
                                            break;
                                        case 'Cortana System Service':
                                            appLogoUrl = '/ui/static/img/desktop/apps/cortana.jpg'
                                            break;
                                        default:
                                            appLogoUrl = '';
                                            break;
                                    }
                                    return (
                                        <>
                                            {appLogoUrl ? (
                                                <AppLogo style={{ backgroundImage: `url(${appLogoUrl})` }} />
                                            ) : (
                                                <div className="default_app_logo">{app.name[0]}</div>
                                            )}
                                            <AppText>{app.name}</AppText>
                                        </>
                                    );
                                })()}
                            </AppLogoContainer>
                        </AppItem>
                    ))}
                </MarqueeGroup>

                <MarqueeGroup>
                    {appsList.map((app: any, index: number) => (
                        <AppItem key={index + 'repeat'}>
                            <AppLogoContainer>
                                {(() => {
                                    let appLogoUrl = '';
                                    switch (app.name) {
                                        case 'Microsoft Teams':
                                        case 'Microsoft Teams Update':
                                            appLogoUrl = '/ui/static/img/desktop/apps/teams.png'
                                            break;
                                        case 'Google Chrome':
                                        case 'Google Chrome Installer':
                                            appLogoUrl = '/ui/static/img/desktop/apps/chrome.png'
                                            break;
                                        case 'Office':
                                            appLogoUrl = '/ui/static/img/desktop/apps/office.png'
                                            break;
                                        case 'Microsoft Outlook':
                                            appLogoUrl = '/ui/static/img/desktop/apps/outlook.png'
                                            break;
                                        case 'Snow':
                                            appLogoUrl = '/ui/static/img/desktop/apps/snow.png'
                                            break;
                                        case 'Postman':
                                            appLogoUrl = '/ui/static/img/desktop/apps/postman.png'
                                            break;
                                        case 'Microsoft Edge':
                                        case 'Microsoft Edge Installer':
                                        case 'Microsoft Edge Update':
                                            appLogoUrl = '/ui/static/img/desktop/apps/edge.jpg'
                                            break;
                                        case 'Vscode':
                                            appLogoUrl = '/ui/static/img/desktop/apps/vscode.png'
                                            break;
                                        case 'The OpenSSL Toolkit':
                                            appLogoUrl = '/ui/static/img/desktop/apps/ssl.png'
                                            break;
                                        case 'Nexthink Finder':
                                            appLogoUrl = '/ui/static/img/desktop/apps/Nexthink1.webp'
                                            break;
                                        case 'Windows Search':
                                            appLogoUrl = '/ui/static/img/desktop/apps/windows_search.png'
                                            break;
                                        case 'Azure Log Analytics':
                                            appLogoUrl = '/ui/static/img/desktop/apps/azureLogs.png'
                                            break;
                                        case 'Cisco Webex Meeting':
                                            appLogoUrl = '/ui/static/img/desktop/apps/cisco.PNG'
                                            break;
                                        case 'Xbox Game Bar':
                                            appLogoUrl = '/ui/static/img/desktop/apps/xbox.webp'
                                            break;
                                        case 'Cortana System Service':
                                            appLogoUrl = '/ui/static/img/desktop/apps/cortana.jpg'
                                            break;
                                        default:
                                            appLogoUrl = '';
                                            break;
                                    }
                                    return (
                                        <>
                                            {appLogoUrl ? (
                                                <AppLogo style={{ backgroundImage: `url(${appLogoUrl})` }} />
                                            ) : (
                                                <div className="default_app_logo">{app.name[0]}</div>
                                            )}
                                            <AppText>{app.name}</AppText>
                                        </>
                                    );
                                })()}
                            </AppLogoContainer>
                        </AppItem>
                    ))}
                </MarqueeGroup>

            </MarqueeWrapper>

            {/* Arrow Button */}
            <ArrowButton>
                <Link to={`${redirectUrl}/desktop/apps`}>
                    <ArrowIcon style={{ backgroundImage: `url('/ui/static/img/desktop/apps/forward.png')` }} />
                </Link>
            </ArrowButton>
        </Container>
    );
};

export default AppsList;

const MarqueeWrapper = styled.div`
  display: flex;
  width: 80%;
  overflow: hidden;
  mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
`;

const scrollX = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const commonMarqueeStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
    ${commonMarqueeStyle}
`;

const AppItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 40px; Increase padding for more space */
  flex-shrink: 0;
  width: 105px; /* Set a fixed width to prevent items from collapsing */
`;

const AppLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;


const AppText = styled.span`
  font-size: 10px;
  color: black;
  text-align: center;
`;
const ArrowButton = styled.div`
    margin-left: 20px;
    margin-top: -10px;
`;

const ArrowIcon = styled.div`
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
`;
