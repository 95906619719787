import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from 'react-bootstrap/Modal';
import "./TicketDetails.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FeedbackRoundedIcon from "@mui/icons-material/FeedbackRounded";
import { Card } from "react-bootstrap";
import { Chip, TextField } from "@mui/material";

import { useTheme } from '@mui/material';
import { tokens } from "../../../../../../theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  borderRadius: 6,
};

interface fileFormatProps {
  type: string;
  sysId: string;
  enData: string;
  name: string;
}

const TicketDetails: React.FC<{
  ticketDetails: any;
  setModalOpen: any;
  modalOpen: any;
}> = ({ ticketDetails, setModalOpen, modalOpen }) => {
  const handleClose = () => setModalOpen(false);

  console.log("ticket details", ticketDetails);

  const theme = useTheme(); // Access the current theme
  const colors = tokens(theme.palette.mode);
  let color = theme.palette.mode;

  useEffect(() => {
      // Set CSS variables based on the current theme colors
      document.documentElement.style.setProperty('--header-bg-color', colors.headers.backround);
      document.documentElement.style.setProperty('--header-text-color', colors.headers.text);
      document.documentElement.style.setProperty('--box-shadow', colors.headers.boxShadow);

  }, [theme]);

  const formattedDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: any = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  const [fileContent, setFileContent] = useState("");
  const [fileUpload, setFileUpload] = useState<fileFormatProps[]>([]);

  const fileselect = () => {
    document.getElementById("file")?.click();
  };

  const FileChangeEvent = (e: any) => {
    console.log("event", e.target.files[0]);

    const files = e.target.files;

    // if(files.length>2){

    // }
    // else{
    for (var i = 0; i < files.length; i++) {
      console.log("for loop");
      const reader = new FileReader();
      let CurrentFile = files[i];
      console.log(CurrentFile);
      reader.readAsDataURL(files[i]);

      reader.onload = (e) => {
        let fileType = CurrentFile.type;
        let fileName = CurrentFile.name;
        let base64Img = reader?.result as string;

        let ab = base64Img?.split(",");
        console.log("ab", ab);
        base64Img = ab[1];
        console.log(base64Img);

        fileFormat(fileType, "", fileName, base64Img);
        setFileContent(CurrentFile.name);
      };
    }

    // }
  };

  const fileFormat = (type: any, sysId: any, name: any, enData: any) => {
    const data: fileFormatProps = {
      type: type,
      sysId: sysId,
      enData: enData,
      name: name,
    };

    setFileUpload((prevFiles) => [...prevFiles, data]);

    console.log("file upload", fileUpload);
  };

  const removeFile = (file: any) => {
    const index = fileUpload.indexOf(file);
    if(index > -1 ){
      const updatedFiles = [...fileUpload];
      updatedFiles.splice(index, 1);
      setFileUpload(updatedFiles);
    }
  }

  return (
    <div data-bs-theme={color}>
      <Modal
        show={modalOpen}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{padding: '0px !important'}}
      >
        
        <Modal.Header closeButton>
          
          <Modal.Title id="contained-modal-title-vcenter">
            <Typography variant="h6" style={{fontSize: '16px'}}>{ticketDetails.number}</Typography>
            </Modal.Title>
            </Modal.Header>
            <Card>
            {/* <button

              style={{
                minWidth: "36px",
                width: "36px",
                height: "36px",
                borderRadius: "50px",
                color: "white",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}

              className='btn btn-link btn-icn'
              onClick={handleClose}
            >
              <i className="bi bi-x" style={{fontSize: '26px'}}></i>
              {/* <CloseRoundedIcon /> */}
            {/*</button> */}
          {/* </div> */}
          <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "54vh",
              margin: "0px -24px",
              padding: "0px 24px 0px 24px",
            }}
          >
            <b>Short description</b>
              <p>{ticketDetails.short_description}</p>

            <b>Description</b>
            <p>
              {ticketDetails.description
                ? ticketDetails.description
                : "No Description Available"}
            </p>
            <b>Estimated Remaining Time</b>
            <p>{ticketDetails.remainingSLA.remainingSLA}</p>
            <b>Created On</b>
            <p>{ticketDetails.sys_created_on}</p>
            <b>Status</b>
            <p>{ticketDetails.state}</p>
            <b>Attachments Added</b>
            <p>No Attachments Added</p>
            <div className="boxComments">
              <div style={{ display: "flex", gap: "8px" }}>
                <FeedbackRoundedIcon style={{ color: "#1976d2" }} />
                <Typography
                  id="modal-modal-description"
                  style={{
                    marginTop: "0px",
                    fontWeight: 600,
                    fontSize: "14px"
                  }}
                >
                  Comments Added to the Ticket
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  overflowY: "auto",
                  maxHeight: "240px",
                }}
              >
                {ticketDetails.comments.length > 0 ? (
                  <>
                    {ticketDetails.comments.map((comment: any) => (
                      <Card>
                        <Typography
                          style={{
                            marginTop: "0px",
                            fontWeight: 500,
                            fontSize: "11px",
                            color: "black",
                          }}
                        >
                          {comment?.commented_by}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography variant="body1" sx={{ fontSize: "14px" }}>
                            {comment?.comment}
                          </Typography>
                          <Typography variant="body1" sx={{ fontSize: "11px" }}>
                            {formattedDate(comment?.commented_date)}
                          </Typography>
                        </div>
                      </Card>
                    ))}
                  </>
                ) : (
                  <>
                    <Card>
                      <Typography variant="body1" sx={{ fontSize: "14px" }}>
                        No Comments Added
                      </Typography>
                    </Card>
                  </>
                )}
              </div>
            </div>

            <div className="boxAddComment">
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", fontWeight: 600 }}
              >
                Add Comment
              </Typography>

              <textarea />
            </div>

            <div className="boxAddAttachment">
              <div className="attachment">
                <Typography
                  variant="body1"
                  sx={{ fontSize: "14px", fontWeight: 600 }}
                >
                  Attachment
                </Typography>
                <div className="info">
                  Please refrain from uploading the personal data.
                </div>

                <div>
                  {fileUpload?.length > 0 ? (
                    <>
                      {fileUpload.map((file) => (
                         <Chip
                         color="primary"
                         label={file?.name}
                         onDelete={() => removeFile(file)}
                         icon={
                           <i
                             style={{ paddingLeft: "4px" }}
                             className="bi bi-image"
                           ></i>
                         }
                       />
                      ))}
                    </>
                  ) : (
                    <>
                    </>
                  )}

                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .xls, .xlsx, .docx"
                    id="file"
                    value=""
                    style={{ display: "none" }}
                    multiple
                    onChange={FileChangeEvent}
                  />
                  <div>
                    <button
                      style={{backgroundColor: 'var(--header-bg-color)',  position: 'relative',
                      border: '1px dashed #34C8FF',
                      /* width: 98%; */
                      height: '56px',
                      borderRadius: '6px',
                      padding: '1%',
                      margin: '2% 0%',
                      cursor: 'pointer',
                      display: 'transparent',
                      marginBottom: '0px'}}
                      id="attachbtn"
                      onClick={fileselect}
                    >
                      <div className="attachtext1">
                        <p style={{margin: 0}}>+ Attach a file</p>
                      </div>
                      <div className="attachtext2"  style={{color: 'var(--header-text-color)'}}>
                        <p>200 mb file</p>
                      </div>
                    </button>
                  </div>

                  {/* <div class="error3" id="error3" style="display: none;" >
                        {{i18nTexts.error}}</div> */}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <button
              className="btn btn-primary"
              style={{width: '120px'}}
              // variant="contained"
              // style={{backgroundColor: '#1976d2'}}
              // sx={{ width: "120px", borderRadius: "50px" }}
            >
              Update
            </button>
          </div>
          </Modal.Body>
          </Card>
        
      </Modal>
    </div>
  );
};

export default TicketDetails;
