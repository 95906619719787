import React, { useEffect, useState } from "react";
import "./Catalog.css";
import {
  getTopCatalogsURL,
  getTopIncidentsURL,
} from "../../../utils/constants";
import Loading from "../../../../../../application/commons/loading/loading";
import { appConfig } from "../../../../../../utils/app.config";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Catalog: React.FC = () => {
  // State to track the active button
  const [activeButton, setActiveButton] = useState("Incident");
  const [topIncidents, setTopIncidents] = useState([]);
  const [topCatalogList, setTopCatalogList] = useState([]);
  const [loadingIncidents, setLoadingIncidents] = useState(true);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const redirectUrl: string = useSelector(
    (state: any) => state.headers.redirectUrl
  );

  useEffect(() => {
    getTopIncidents();
    getTopCatalogList();
  }, []);

  const getTopIncidents = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const result = await (await fetch(getTopIncidentsURL, options)).json();
      if (result) {
        setTopIncidents(result);
        setLoadingIncidents(false);
      }
    } catch (err) {
      console.log("ERROR :::", err);
      setTopIncidents([]);
      setLoadingIncidents(false);
    }
  };

  const getTopCatalogList = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const result = await (await fetch(getTopCatalogsURL, options)).json();
      if (result) {
        setTopCatalogList(result);
        setLoadingCatalogs(false);
      }
    } catch (err) {
      console.log("ERROR :::", err);
      setTopCatalogList([]);
      setLoadingCatalogs(false);
    }
  };

  const handleIncidentClick = (incident: any) => {
    console.log("Incident Clicked", incident["number"]);
    console.log(incident);
  };

  return (
    <div className="catalog-main-container">
      <div className="catalog-buttons-container">
        <div className="catalog-incident-container">
          <button
            className={`catalog-button ${
              activeButton === "Incident" ? "active" : ""
            }`}
            onClick={() => setActiveButton("Incident")}
          >
            Incident
          </button>
        </div>
        <div className="catalog-raise-catalog-container">
          <button
            className={`catalog-button ${
              activeButton === "RaiseCatalog" ? "active" : ""
            }`}
            onClick={() => setActiveButton("RaiseCatalog")}
          >
            Raise Catalog
          </button>
        </div>
      </div>
      {/* Conditional rendering of content based on the active button */}
      <div className="catalog-main-content-display">
        {activeButton === "Incident" &&
          (loadingIncidents ? (
            <Loading message="Loading Incidents..." />
          ) : (
            <>
              <div className="topIncident-title">
                Top {topIncidents.length} Incident List
              </div>
              <ul className="catalog-main-list">
                {topIncidents.map((incident, index) => (
                  <Link
                    to={`${redirectUrl}/desktop/raise-ticket`}
                    state={{ ticketData: incident, type: "Incident" }}
                    style={{textDecoration: 'auto', color: 'black'}}
                  >
                    <li
                      className="topIncident-incident"
                      key={index}
                      onClick={() => handleIncidentClick(incident)}
                    >
                      <img
                        className="incidentIcon"
                        src={appConfig.imagesUrl + "/desktop/incident.png"}
                      />{" "}
                      {incident["short_description"]}
                    </li>
                  </Link>
                ))}
              </ul>
            </>
          ))}

        {activeButton === "RaiseCatalog" &&
          (loadingCatalogs ? (
            <Loading message="Loading Raise Catalogs..." />
          ) : (
            <>
              <div className="topCatalog-title">
                Top {topCatalogList.length} Catalog List
              </div>
              <ul className="catalog-main-list">
                {topCatalogList.map((incident, index) => (
                  <Link
                    to={`${redirectUrl}/desktop/raise-ticket`}
                    state={{ ticketData: incident, type: "Catalog" }}
                    style={{textDecoration: 'auto', color: 'black'}}

                  >
                    <li
                      className="topCatalog-catalog"
                      key={index}
                      onClick={() => handleIncidentClick(incident)}
                    >
                      <img
                        className="catalogIcon"
                        src={appConfig.imagesUrl + "/desktop/Catalog.png"}
                      />{" "}
                      {incident["short_description"]}
                    </li>
                  </Link>
                ))}
              </ul>
            </>
          ))}
      </div>
    </div>
  );
};

export default Catalog;
