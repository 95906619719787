import React, { useEffect, useState } from 'react';
import { Nav, Container, Form, Button, Row, Col} from 'react-bootstrap';
import { useTheme } from '@mui/material';
import "./user-insight.css";

import UserSection from './components/User/user-section';
import DeviceSection from './components/Device/device-section';
import SupportSection from './components/Support/support-section';



const UserInsight: React.FC = () => {

    //can have values User-content, Device-content, Support-content
    const [activeContent, setActiveContent] = useState('user');
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    let theme = useTheme();
    let color = theme.palette.mode;


    const userClick = () => setActiveContent('user');
    const deviceClick = () => setActiveContent('device');
    const supportClick = () => setActiveContent('support');

    const handleSearch = (e: any) => {
        e.preventDefault();
        alert(`You searched for: ${searchQuery}`);
    };

    const toggleExpand = () => {
        setIsSearchExpanded((prev) => !prev);
    };

    return (
        // <div data-bs-theme={color}>
        <div data-bs-theme={color} className={`${color == 'dark'? 'dark-theme': 'light-theme'}`}>
            <Container >
            {/* className="mt-4" */}
                <div className={`nav-row`}>
                <Row className='align-items-center'>
                    <Col md={11}>
                        <div className='navbar-class'>
                            <div className={`${activeContent == 'user' ? 'sel-nav-button' : 'nav-button'}`} onClick={userClick}>
                                <i className="bi bi-person-fill"></i>
                                User
                            </div>
                            <div className={`${activeContent == 'device' ? 'sel-nav-button' : 'nav-button'}`} onClick={deviceClick}>
                                <i className="bi bi-pc-display"></i>
                                Device
                            </div>
                            <div className={`${activeContent == 'support' ? 'sel-nav-button' : 'nav-button'}`} onClick={supportClick}>
                                <i className="bi bi-key-fill"></i>
                                Support
                            </div>
                        </div>
                        
                    </Col>

                    <Col md={1}>
                        <Form
                            className="d-flex justify-content-end"
                            onSubmit={handleSearch}
                        >
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                className={`search-input ${isSearchExpanded ? 'expanded' : ''}`}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div
                                onClick={toggleExpand}
                                className="search-button"
                            >
                                <i className="bi bi-search"></i>
                            </div>
                            
                            
                        </Form>
                    </Col>
                </Row>
                </div>
                <Row className="mt-4">
                    <Col>
                        {/* Display dynamic content bases on which section - user/device/support is selected */}
                        {activeContent === 'user' && <div className={`main-content ${color == 'dark'? 'dark-theme': 'light-theme'}`}><UserSection /></div>}
                        {activeContent === 'device' && <div className={`main-content ${color == 'dark'? 'dark-theme': 'light-theme'}`}><DeviceSection /></div>}
                        {activeContent === 'support' && <div className={`main-content ${color == 'dark'? 'dark-theme': 'light-theme'}`}><SupportSection /></div>}
                    </Col>
                </Row>
                
            </Container>
        </div>
    );
}

export default UserInsight;