import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Row, Col, InputGroup, Alert } from 'react-bootstrap';
import "./admin.page.css"
import { appConfig } from '../../../../../utils/app.config';
import { Label } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getUserProfileUrl } from '../../../../../utils/constants/constants';
import { updateLoginDetails } from '../../../../../utils/redux-store/slices/loginSlice';
import { useDispatch } from 'react-redux';
import { isEmptyObject } from '../../../../../utils/helpers';
import Loading from '../../../../../application/commons/loading/loading';

interface UsersProps {
    _id: string;
    displayName: string;
    family_name: string;
    given_name: string;
    email: string;
}

const AdminPage: React.FC = () => {
    const [users, setUsers] = useState<UsersProps[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRole, setSelectedRole] = useState<{ [key: string]: string }>({});
    const [error, setError] = useState<{ [key: string]: string }>({}); // Validation error
    const [success, setSuccess] = useState<{ [key: string]: string }>({});
    const userProfile = useSelector((store: any) => store.login.loginDetails);
    console.log('iser', userProfile)
    const dispatch = useDispatch();

    // Function to fetch users from the temporary database
    const fetchUsers = async () => {
        try {
            const token = sessionStorage.getItem(appConfig.loginTokenName);
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                }
            };
            const result = await (await fetch(`${appConfig.baseUrl}/auth/tmp/user/oauth2/cdd`, options)).json();
            if (result) {
                setUsers(result);
            }
        } catch (err) {
            console.log('ERROR :::', err);
        }
    };

    const getUserProfile = async () => {
        try {
            const token = sessionStorage.getItem(appConfig.loginTokenName);
            console.log(token)
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                }
            };
            const result = await (await fetch(getUserProfileUrl, options)).json();
            if (result) {
                dispatch(updateLoginDetails({ value: result.payload }));
            }
        } catch (err) {
            console.log('ERROR :::', err);
            dispatch(updateLoginDetails({ value: {} }));
        }
    }
    // Fetch users when the component loads
    useEffect(() => {
        getUserProfile();
        fetchUsers();
    }, []);

    // Search filter
    const filteredUsers = users.filter(user =>
        user.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const handleRoleChange = (id: string, role: string) => {
        setSelectedRole((prevState) => ({
            ...prevState,
            [id]: role, // Ensure the role is updated for the specific user ID
        }));
        setError({ ...error, [id]: '' }); // Clear any previous error if role is selected
    };

    const handleDeleteUser = async (user: UsersProps) => {
        try {
            const token = sessionStorage.getItem(appConfig.loginTokenName);
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: user.email
                })
            };
            const result = await (await fetch(`${appConfig.baseUrl}/auth/remove/tmp/user/oauth2/cdd`, options)).json();
            if (result['status'] === 200) {
                setSuccess({ ...success, [user._id]: result['message'] });
                setError({ ...error, [user._id]: '' }); // Clear any previous error
                setTimeout(fetchUsers, 1000)
            }
        } catch (err) {
            console.log('ERROR :::', err);
        }
    }

    const handleAddUser = async (user: UsersProps) => {
        try {
            if (selectedRole[user._id]) {
                const token = sessionStorage.getItem(appConfig.loginTokenName);
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        "email": user.email,
                        "fullName": user.displayName,
                        "firstName": user.given_name,
                        "lastName": user.family_name,
                        "role": selectedRole[user._id].toLowerCase(),
                        "registerBy": userProfile['raw']['raw']['mail']
                    })
                };
                const result = await (await fetch(`${appConfig.baseUrl}/auth/move/tmp/user/oauth2/cdd`, options)).json();
                console.log('[result]', result)
                if (result['status'] === 201) {
                    setSuccess({ ...success, [user._id]: result['message'] });
                    setTimeout(fetchUsers, 1000)
                }
                else if (result['status'] === 200) {
                    setError({ ...error, [user._id]: result['message'] });
                    setTimeout(() => handleDeleteUser(user), 1000)
                }
                else {
                    setError({ ...error, [user._id]: result['message'] });
                }
            }
            else {
                setError({ ...error, [user._id]: 'Please select the role' });
            }
        } catch (err) {
            console.log('ERROR :::', err);
        }
    }

    if (isEmptyObject(userProfile)) {
        return <Loading />
    }

    if (userProfile['raw']['role'] !== 'admin') {
        return <label style={{ textAlign: 'center' }}>Either you don't have permission for this page or page is not found.</label>
    }

    return (
        <div style={{ width: '100%', padding: '16px' }}>
            <h1 style={{ textAlign: 'center', padding: '5px' }}>Manage Users</h1>
            <div className="search-bar">
                <img className='search-icon' src="/ui/static/img/desktop/Icon_feather_search.png" alt="search" />
                <input
                    type="text"
                    placeholder="Search users by name or email"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            {/* Display users in card form */}
            <div className='admin_page_display_cards'>
                {filteredUsers.map((user: UsersProps) => (
                    // <Col key={user._id} className="mb-4">
                    <Card key={user._id} className="shadow-lg p-3 mb-5 mx-1 bg-white rounded card-fixed-size" >
                        <Card.Body>
                            <Card.Title>{user.displayName}</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">{user.email}</Card.Subtitle>

                            {/* Role Selection */}
                            <Form.Group controlId={`roleSelect-${user._id}`}>
                                <Form.Label>Select Role</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedRole[user._id] || ''}
                                    onChange={(e) => handleRoleChange(user._id, e.target.value)}
                                >
                                    <option value="">Select Role...</option>
                                    <option value="User">User</option>
                                    <option value="Admin">Admin</option>
                                </Form.Control>
                                {error[user._id] && (
                                    <Alert variant="danger" className="mt-2">
                                        {error[user._id]}
                                    </Alert>
                                )}
                                {success[user._id] && (
                                    <Alert variant="success" className="mt-2">
                                        {success[user._id]}
                                    </Alert>
                                )}
                            </Form.Group>

                            {/* Add and Delete Buttons */}
                            <div className='w-100 d-flex'>
                                <Button
                                    variant="success"
                                    onClick={() => handleAddUser(user)}
                                    className="w-40 mx-1"
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="danger"
                                    className="w-40 mx-1"
                                    onClick={() => handleDeleteUser(user)}
                                >
                                    Delete
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                    // </Col>
                ))}
            </div>
        </div >
    );
};

export default AdminPage;
