import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define the initial state structure
interface ticketsCva {
    ticketsCva: Record<string, any>[],
}

const initialState: ticketsCva = {
    ticketsCva: []
};


// Create the tickets Cva slice

const TicketsCvaSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers:  {
        updateTicketsCva: (state: ticketsCva, action: PayloadAction<{ value: Record<string, any>[] }>) => {
            state.ticketsCva = action.payload.value;
        },
    },
})

// Export actions and reducer
export const { updateTicketsCva } = TicketsCvaSlice.actions;
export default TicketsCvaSlice.reducer; 
