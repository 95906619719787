import React, { useEffect, useState } from 'react';
import './camera-modal.css'
import { appConfig } from '../../../../utils/app.config';
import { updateUserProfilePictureUrl } from '../../../../utils/constants/constants';

interface CameraModalProps {
    isOpen: boolean;
    onClose: () => void;
    profilePicture: string | null;
    setProfilePicture: (value: string | null) => void;
    initials: string;
    email: string;
}

const CameraModal: React.FC<CameraModalProps> = ({ isOpen, onClose, email, profilePicture, setProfilePicture, initials }) => {
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [tmpProfilePic, setTmpProfilePic] = useState<string | null>(profilePicture);
    const [updateProfilePic, setUpdatedProfilePic] = useState<string | null>(null)
    const [save, setSave] = useState(false);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        const maxFileSize = 5 * 1024 * 1024; // 5 MB
        const maxDisplaySize = 150; // Max dimensions: 150px x 150px

        if (file) {
            const fileType = file.type;
            const fileSize = file.size;

            if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
                setSuccess(null);
                setError('Only JPEG/PNG images are allowed.');
                return;
            }

            if (fileSize > maxFileSize) {
                setSuccess(null);
                setError('File size should not exceed 5 MB.');
                return;
            }

            setError(null); // Clear any previous error

            // Convert the file to Base64 and resize if necessary
            const reader = new FileReader();
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result as string;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const scale = Math.min(maxDisplaySize / img.width, maxDisplaySize / img.height);
                    canvas.width = img.width * scale;
                    canvas.height = img.height * scale;

                    const ctx = canvas.getContext('2d');
                    if (ctx) {
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        const resizedImage = canvas.toDataURL(fileType);
                        setTmpProfilePic(resizedImage); // Save the resized Base64 string
                        setSave(true);
                    }
                };
            };
            reader.readAsDataURL(file);
        }
    };


    const handleSaveProfile = () => {
        if (tmpProfilePic) {
            setUpdatedProfilePic(tmpProfilePic);
        }
    }

    useEffect(() => {
        if (updateProfilePic) {
            sendUpdatedProfilePicture(updateProfilePic)
        }
    }, [updateProfilePic])


    const sendUpdatedProfilePicture = async (profilePic: any) => {
        try {
            const token = sessionStorage.getItem(appConfig.loginTokenName);

            const response = await (await fetch(updateUserProfilePictureUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: email,
                    profilePicture: profilePic
                })
            })).json();

            if (response['status'] === 200) {
                console.log("Profile Picture successfully.");
                setSuccess(response['message'])
                setProfilePicture(profilePic);
            } else {
                console.error("Error updating profile.");
                setError(response['message'])
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleRemoveProfilePicture = async () => {
        await sendUpdatedProfilePicture(null);
        setTmpProfilePic(null);
    }

    if (!isOpen) return null;

    return (
        <div className='camera-container'>
            <div className="camera-modal-overlay">
                <div className="camera-modal-content">
                    <div className="camera-modal-header">
                        <h2>Change your profile picture</h2>
                        <button className="camera-close-button" onClick={onClose}>✖</button>
                    </div>
                    <div className="camera-modal-body">
                        <div className='camera-modal-profile-pic'>
                            {
                                !tmpProfilePic ? <div className="camera_modal_userprofile_circle">
                                    <div className="camera_modal_userprofile_initials" id="img">
                                        {initials}
                                    </div>
                                </div> :
                                    <div className="camera_modal_userprofile_container_profile_pic">
                                        <img className="camera_modal_userprofile_profile_pic" src={tmpProfilePic} alt="Profile" />
                                    </div>
                            }
                        </div>
                        <div className='camera-modal-body-buttons'>
                            <input
                                id="file-upload"
                                className="profile-file-upload"
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                            <button className='camera-upload-button' onClick={() => document.getElementById('file-upload')?.click()}><i className="bi bi-upload"></i>Upload</button>
                            <button className='camera-remove-button' onClick={handleRemoveProfilePicture}><i className="bi bi-trash"></i>Remove</button>
                        </div>
                        {success && <div className="camera_modal_update_success">{success}</div>}
                        {error && <div className='camera_modal_pic_error'>{error}</div>}
                    </div>
                    <div className="camera-modal-footer">
                        <div className='camera-modal-footer-content'>
                            It’ll update your current profile picture.
                        </div>
                        <div className='camera-modal-footer-buttons'>
                            <button className="camera-cancel-button" onClick={onClose}>Close</button>
                            <button className={`camera-confirm-button ${!save ? "disabled" : ""}`} onClick={handleSaveProfile} disabled={!save}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CameraModal;