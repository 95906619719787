import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define the initial state structure
interface DesktopApps {
    desktopApps: Record<string, any>[],
}

const initialState: DesktopApps = {
    desktopApps: []
};

// Create the login slice
const desktopAppsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        updateDesktopApps: (state: DesktopApps, action: PayloadAction<{ value: Record<string, any>[] }>) => {
            state.desktopApps = action.payload.value;
        },
    },
});

// Export actions and reducer
export const { updateDesktopApps } = desktopAppsSlice.actions;
export default desktopAppsSlice.reducer;