// src/components/ModuleAccess.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './module.access.user.comp.css'; // Import the CSS file for styling
import { Table } from 'react-bootstrap'; // Make sure to import Bootstrap components
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap styles are included
import { appConfig } from '../../../../utils/app.config';

export const ModuleAccessUser: React.FC = () => {
    const [users, setUsers] = useState([]);
    const [selectedUserEmail, setSelectedUserEmail] = useState('');
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [modules, setModules] = useState([]);
    const baseUrl = appConfig.apiUrl + '/v2.0/dummy';

    useEffect(() => {
        // Fetch users and modules from the REST API
        const fetchData = async () => {
            try {
                const usersResponse = await axios.get(`${baseUrl}/users`); // Change to your users API endpoint
                setUsers(usersResponse.data);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // Fetch modules for the selected user
        const fetchModules = async () => {
            if (selectedUserEmail) {
                try {
                    const modulesResponse = await axios.get(`${baseUrl}/modules?userEmail=${selectedUserEmail}`); // Adjust API endpoint if needed
                    setModules(modulesResponse.data);
                    const userResponse = await axios.get(`${baseUrl}/users?email=${selectedUserEmail}`);
                    setSelectedUser(userResponse.data[0]); // Assuming the user data is returned as an array
                } catch (error) {
                    console.error("Error fetching modules:", error);
                }
            }
        };

        fetchModules();
    }, [selectedUserEmail]);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedUserEmail(event.target.value);
    };

    return (
        <div className="module-access">
            <div className="form-group">
                <label htmlFor="userEmail">Enter Prompt Name</label>
                <input
                    type="text"
                    id="userEmail"
                    value={selectedUserEmail}
                    onChange={handleEmailChange}
                    placeholder="Type to autocomplete..."
                    list="user-emails"
                    className="form-control"
                />
                <datalist id="user-emails">
                    {users.map((user: any) => (
                        <option key={user.id} value={user.email} />
                    ))}
                </datalist>
            </div>

            {selectedUser && (
                <div className="user-details">
                    <h5>User Details:</h5>
                    <p><strong>Email:</strong> {selectedUser.email}</p>
                    <p><strong>Full Name:</strong> {selectedUser.fullName}</p>
                    <p><strong>First Name:</strong> {selectedUser.firstName}</p>
                    <p><strong>Last Name:</strong> {selectedUser.lastName}</p>
                    <p><strong>Role:</strong> {selectedUser.role}</p>
                </div>
            )}

            {modules.length > 0 && (
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Module Name</th>
                            <th>Description</th>
                            <th>Access</th>
                        </tr>
                    </thead>
                    <tbody>
                        {modules.map((module: any, index) => (
                            <tr key={module.id}>
                                <td>{index + 1}</td>
                                <td>{module.name}</td>
                                <td>{module.description}</td>
                                <td>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={module.access}
                                            onChange={() => {
                                                // Handle access toggle
                                                const updatedModules: any = [...modules];
                                                updatedModules[index].access = !module.access;
                                                setModules(updatedModules);
                                            }}
                                            id={`moduleAccessSwitch-${module.id}`} // Unique ID for each switch
                                        />
                                        <label className="form-check-label" htmlFor={`moduleAccessSwitch-${module.id}`}>
                                            {module.access ? 'On' : 'Off'}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </div>
    );
};
