import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ApplicationLayout } from '../application.layout';
import ChatRoutes from '../../../modules/chat/chat.routes';
import ResetPassword from '../../commons/reset-password/reset.password';
import DesktopRoutes from '../../../modules/apps/desktop-app/desktop.app.router';

const ApplicationRoutes: React.FC = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<ApplicationLayout />}>
                    <Route index element={<Navigate to="/dasboard" />} />
                    <Route path="chat/*" element={<ChatRoutes />} />
                    <Route path="desktop/*" element={<DesktopRoutes redirectUrl='/ap'/>} />
                    <Route path='/reset-password' element={<ResetPassword />} />
                </Route>
            </Routes>
        </div>
    );
};



export default ApplicationRoutes;
