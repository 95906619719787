import React, { useState } from 'react';
import './SearchApps.css';
import { useSelector } from 'react-redux';
import BackButton from '../../../../../../application/commons/back-button/back-button';

const SearchApps: React.FC = () => {
    const appsList = useSelector((store: any) => store.desktopApps.desktopApps);
    const [searchQuery, setSearchQuery] = useState<string>('');

    // Filter apps based on the search query if user is typing
    const filteredApps = appsList.filter((app: any) =>
        app.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleAppClick = () => {
        console.log('APP CLICKED');
    }
    // Show filtered apps or the full list if no query
    const appsToDisplay = searchQuery ? filteredApps : appsList;

    return (
        <>
            <BackButton />
            <div className="search-container">
                <h1 style={{ textAlign: 'center', padding: '5px' }}>Apps</h1>
                <div className="search-bar">
                    <img className='search-icon' src="/ui/static/img/desktop/Icon_feather_search.png" alt="search" />
                    <input
                        type="text"
                        placeholder="Search for an app..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                {/* <div className="apps-grid"> */}
                {appsToDisplay.length > 0 ? (
                    <div className="apps-grid"> {
                        appsToDisplay.map((app: any, index: number) => (
                            <div key={index} className="searchapps_app_box hover_scale_searchapp" onClick={handleAppClick}>
                                <div className="searchapps_app_logo">
                                    {(() => {
                                        switch (app.name) {
                                            case 'Microsoft Teams':
                                            case 'Microsoft Teams Update':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/teams.png')`,
                                                }}></div>;
                                            case 'Google Chrome':
                                            case 'Google Chrome Installer':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/chrome.png')`,
                                                }}></div>;
                                            case 'Office':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/office.png')`,
                                                }}></div>;
                                            case 'Microsoft Outlook':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/outlook.png')`,
                                                }}></div>;
                                            case 'Snow':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/snow.png')`,
                                                }}></div>;
                                            case 'Postman':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/postman.png')`,
                                                }}></div>;
                                            case 'Microsoft Edge':
                                            case 'Microsoft Edge Installer':
                                            case 'Microsoft Edge Update':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/edge.jpg')`,
                                                }}></div>;
                                            case 'Vscode':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/vscode.png')`,
                                                }}></div>;
                                            case 'The OpenSSL Toolkit':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/ssl.png')`,
                                                }}></div>;
                                            case 'Nexthink Finder':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/Nexthink1.webp')`,
                                                }}></div>;
                                            case 'Windows Search':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/windows_search.png')`,
                                                }}></div>;
                                            case 'Azure Log Analytics':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/azureLogs.png')`,
                                                }}></div>;
                                            case 'Cisco Webex Meeting':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/cisco.PNG')`,
                                                }}></div>;
                                            case 'Xbox Game Bar':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/xbox.webp')`,
                                                }}></div>;
                                            case 'Cortana System Service':
                                                return <div className="appslist_app_logo1" style={{
                                                    backgroundImage: `url('/ui/static/img/desktop/apps/cortana.jpg')`
                                                }}></div>;
                                            default:
                                                return <div className="default_app_logo">{app.name[0]}</div>;
                                        }
                                    })()}
                                </div>
                                <p className='searchapps_app_title'>{app.name}</p>
                            </div>
                        ))
                    }
                    </div>
                ) : (
                    <div className='searchapps_no_app'>
                        <p>No apps found matching {searchQuery}</p>
                    </div>
                )}
                {/* </div> */}
            </div>
        </>
    );
};

export default SearchApps;
