
const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

export const appConfig = {
    baseUrl: baseUrl,
    apiUrl: process.env.REACT_APP_API_ENDPOINT || `${baseUrl}/api`,
    env: process.env.REACT_APP_ENV || 'local',
    landingPage: `${baseUrl}/ui/loginSelection`,
    loginUrl: `${baseUrl}/auth/login/oauth2?provider=4`,
    imagesUrl: baseUrl + '/ui/static/img',
    loginTokenName: "cva.jwt.access.token"
}


