import React, { useEffect, useState } from 'react';
import './Queries.css';
import { getTopKbsURL } from '../../utils/constants';
import Loading from '../../../../../application/commons/loading/loading';

const Queries: React.FC = () => {
    // State to track the active button
    const [topKbs, setTopKbs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getTopKbs();
    }, [])


    const getTopKbs = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const result = await (await fetch(getTopKbsURL + "?userid=Sachin.k@tcsddp.com", options)).json();
            if (result) {
                setTopKbs(result)
            }
        } catch (err) {
            console.log('ERROR :::', err);
            setTopKbs([])
        }
        setTimeout(() => setLoading(false), 200);
    }


    const handleKbClick = (kb: any) => {
        console.log('KB Clicked', kb['short_description']);
        console.log(kb)
    }


    if (loading) {
        return <Loading />
    }

    return (
        <div className='queries-main-container'>
            {
                topKbs.length === 0 && <p className='nokbs'>No queries available.</p>
            }
            {topKbs.length > 0 &&
                (<>
                    <ul>
                        {topKbs.map((kb, index) => (
                            <li className='topKbs-kb' key={index} onClick={() => handleKbClick(kb)}>
                                {kb['short_description']}
                            </li>
                        ))}
                    </ul>
                </>
                )
            }
        </div>
    );
};

export default Queries;
