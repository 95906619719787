import React, { useState } from 'react';
import './add.uer.comp.css';

export const AddUser: React.FC = () => {
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');

    const generatePassword = () => {
        // Implement your password generation logic here
        const randomPassword = Math.random().toString(36).slice(-8); // Example: Generates an 8 character password
        setPassword(randomPassword);
    };

    const handleRegister = () => {
        // Implement your registration logic here
        console.log({ email, fullName, firstName, lastName, role, password });
    };

    return (
        <div style={{padding: '0px'}} className="container mt-5">
            <h2>Register User</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label>Email ID</label>
                        <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Full Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>First Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input
                            type="text"
                            className="form-control"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Role</label>
                        <select
                            className="form-control"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        >
                            <option value="">Select Role</option>
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                            {/* Add more roles as needed */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <div className="input-group">
                            <input
                                type="password"
                                className="form-control"
                                value={password}
                                readOnly
                            />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-secondary"
                                    onClick={() => navigator.clipboard.writeText(password)}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-primary" onClick={generatePassword}>
                        Generate Password
                    </button>
                    <button className="btn btn-success mt-3" onClick={handleRegister}>
                        Register
                    </button>
                </div>
            </div>
        </div>
    );
};

