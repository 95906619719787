import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import { appConfig } from './utils/app.config';
import Login from './application/commons/login/login.comp';
import MenuRoutes from './application/layout/routes/menu.routes';
import ApplicationRoutes from './application/layout/routes/application.routes';
import BasicRoutes from './application/layout/routes/basic.routes';

const App: React.FC = () => {
    const location = useLocation();
    const parsedUrl = new URL(appConfig.landingPage);
    let pathname = parsedUrl.pathname;
    const basePath = '/ui';
    if (pathname.startsWith(basePath)) {
        pathname = pathname.replace(basePath, '');
    }
    let locationPath = location.pathname;
    if (locationPath.endsWith("/")) {
        locationPath = locationPath.substring(0, locationPath.length - 1);
    }

    useEffect(() => {
        if (pathname !== locationPath && !localStorage.getItem('cva.ui.islanding')) {
            localStorage.setItem('cva.ui.islanding', "true");
            window.location.href = appConfig.landingPage;
        } else {
            console.log("You are on the correct page:", location.pathname);
        }
    }, [location]);
    return (
        <Routes>
            <Route path="/" element={<BasicRoutes />} />
            <Route index element={<BasicRoutes />} />
            <Route path="/*" element={<BasicRoutes />} />
            <Route path="mn/*" element={<Login><MenuRoutes /></Login>} />
            <Route path="ap/*" element={<Login><ApplicationRoutes /></Login>} />
   
        </Routes>

    );
};


export default App;
