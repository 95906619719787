
import React from 'react';
import './privacy-policy.css';

const PrivacyPolicy: React.FC= () => {
    return (
        <>
            <div className="privacy-policy">
                <h1>Privacy Policy 2023</h1>
                <div className="content">
                    <p>
                        Introduction Your privacy is important to us. We believe it is
                        important for you to know how we treat your personal information. This privacy statement explains what
                        personal data we, Tata Consultancy Services (“TCS” or “we”), collect from you, through your usage of our TCS
                        CVA (Cognix Virtual Assistant), how we use it and which rights you might be entitled to as data subject. TCS
                        is the “data controller”. This means that we are responsible for deciding how we hold and use personal data
                        about you. We are required under data protection legislation to notify you of the information contained in
                        this privacy notice. It is important that you read this notice, together with any additional and more
                        specific information we may provide to you on various occasions when we are collecting or processing
                        personal data on certain specific applications so that you are aware of how and why we are using such
                        information. We will comply with applicable data protection laws. This means that the personal data we hold
                        about you will be:
                        <br /><br />1. Used lawfully, fairly, and in a transparent way.<br />
                        <br />2. Collected only for valid purposes that we have clearly explained to you and not used in any way that
                        is incompatible with those purposes.<br />
                        <br />3. Relevant to the purposes we have told you about and limited only to those purposes.<br />
                        <br />4. Accurate and kept up to date.<br />
                        <br />5. Kept only as long as necessary for the purposes we have told you about.<br />
                        <br />6. Kept securely.<br /><br />
                        In simple terms How we will use your personal information TCS CVA (Cognix Virtual Assistant) is a platform
                        that consolidates your data in one easy-to-use application. We will collect and use different kinds of
                        personal information according to the various situations and interactions with you; from time to time, you
                        will also have the opportunity to choose what data you share and with whom. We will only use your personal
                        data for the purposes for which we collected it unless we reasonably consider that we need to use it for
                        another reason and that reason is compatible with the original purpose. If we need to use your personal data
                        for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
                        Please note that we may process your personal data without your knowledge or consent, in compliance with the
                        above rules, where this is required or permitted by law. How we will keep your information safe We have put
                        in place measures to protect the security of your information; details of these measures are available on
                        request. We have put in place appropriate technical, organizational, and security measures to prevent your
                        personal data from being accidentally lost, used or accessed in an unauthorized way, altered, or disclosed.
                        We have put in place procedures to deal with any suspected data security breach and will notify you and any
                        applicable regulator of a suspected breach where we are legally required to do so.
                        <br /><br />
                        We are also collecting your mobile number to send it to our third party which is a contact centre executive. This mobile number will help executive to connect you back to resolve your query. We make sure that your mobile number will not be shared with any third-party applications/person.
                        <br /><br />
                        Please contact the below email id for any data privacy related queries:
                        <br />
                        Admin@xyz.com
                    </p>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;

