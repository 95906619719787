import React, { useEffect, useState } from "react";
import "./KbList.css";
import SearchBar from "../SearchBar/SearchBar";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Box, Tab, Tabs, Card, Typography, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { getSearchKbURL } from "../../utils/constants";
import BackButton from "../../../../../application/commons/back-button/back-button";
import CustomTabs from "../CustomTabs/CustomTabs";

interface KbListProps {
  searchedResult: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  console.log();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const KbList: React.FC = () => {
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [searched, setSearched] = useState("");
  const [searchKB, setSearchKB] = useState([]);
  const { passedData } = location.state || {};
  const recentSearches = useSelector(
    (store: any) => store.search.recentSearches
  );
  const trendingSearches = useSelector(
    (store: any) => store.search.trendingSearches
  );

  

  // const searchKB: any = useSelector((store: any) => store.kb.searchKb); const getSearchKb = async () => {

  const getSearchKb = async () => {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify( {
          value: passedData
        })
      };
      const result = await (await fetch(getSearchKbURL, options)).json();
      if (result) {
        setSearchKB(result);
        // dispatch(updateSearchKb({ value: result }));
      }
    } catch (err) {
      console.log("ERROR :::", err);
      setSearchKB([]);
      // dispatch(updateSearchKb({ value: [] }));
    }
  };

  console.log("searched Kb", searchKB);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    setSearched(passedData);
    passedData.length > 0 && getSearchKb();
  }, [passedData]);
  console.log(searched);

  const tabData = [
    {
      name: 'All', 
      content:  <div
      style={{
        height: "calc(100vh - 172px)",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        overflowY: "auto",
      }}
    >
      About: {passedData}
      {searchKB.length > 0 ? (
        <>
          {searchKB.map((item: any) => (
            <>
              {item && item.isKb === false ? (
                <>
                  <div className='card shadow-sm' style={{ overflow: "visible", padding: '16px', cursor: "pointer" }}>
                    <div >
                      <p style={{margin:0, fontSize: '14px', fontWeight: 500, color: 'var(--header-text-color)'}}>{item.title}</p>
                      <p
                       
                        style={{ fontSize: "14px", margin: '0px', color: "#a9a9a9" }}
                      >
                        Service Catalog
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Link
                    to="/mn/desktop/kb-article"
                    state={{passedKbArticle: item}}
                    style={{ textDecoration: "none" }}
                  >
                    <div className='card shadow-sm' style={{ overflow: "visible", padding: '16px', cursor: "pointer" }}>
                      <div>
                        <p style={{margin:0, fontSize: '14px', fontWeight: 500, color: 'var(--header-text-color)'}}>{item.title}</p>
                        <p
                         
                          style={{ fontSize: "14px", color: 'var(--header-text-color)'}}
                        >
                          {item.subtitles}
                        </p>
                        <p
                          style={{ fontSize: "14px", color: 'var(--header-text-color)' }}
                        >
                          {item.publishDate} • {item.author}
                        </p>
                      </div>
                    </div>
                  </Link>
                </>
              )}
            </>
          ))}
        </>
      ) : (
        <div>No Search Kb</div>
      )}
      </div>
    },
    {
      name: 'How to?', 
      content: '',
      disabled: true
    },
    {
      name: 'Incidents', 
      content: '',
      disabled: true
    },
    {
      name: 'Service Requests', 
      content: '',
      disabled: true
    }
  ]

  return (
    <>
      <div className="row1">
        <div id="back" className="back">
          <BackButton></BackButton>
        </div>
        <div className="logo_bot" style={{ flex: "1 0 auto" }}>
          <div className="bot_image"></div>
        </div>
        <div className="searchBar" style={{ width: "100%" }}>
          <SearchBar
            trendingSearches={trendingSearches}
            recentSearches={recentSearches}
            searchedValue={searched}
          ></SearchBar>
        </div>
      </div>
      <div className="kbListDiv">
        <CustomTabs data={tabData}/>

        {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="basic tabs example"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="How to?" disabled {...a11yProps(1)} />
            <Tab label="Incidents" disabled {...a11yProps(2)} />
            <Tab label="Service Requests" disabled {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <div
            style={{
              height: "calc(100vh - 172px)",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              overflowY: "auto",
            }}
          >
            About: {passedData}
            {searchKB.length > 0 ? (
              <>
                {searchKB.map((item: any) => (
                  <>
                    {item && item.isKb === false ? (
                      <>
                        <Card sx={{ overflow: "visible", cursor: "pointer" }}>
                          <CardContent>
                            <Typography variant="h6">{item.title}</Typography>
                            <Typography
                              variant="body1"
                              style={{ fontSize: "14px", color: "#a9a9a9" }}
                            >
                              Service Catalog
                            </Typography>
                          </CardContent>
                        </Card>
                      </>
                    ) : (
                      <>
                        <Link
                          to="/mn/desktop/kb-article"
                          state={{passedKbArticle: item}}
                          style={{ textDecoration: "none" }}
                        >
                          <Card sx={{ overflow: "visible", cursor: "pointer" }}>
                            <CardContent>
                              <Typography variant="h6">{item.title}</Typography>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "14px", color: "#a9a9a9" }}
                              >
                                {item.subtitles}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ fontSize: "14px" }}
                              >
                                {item.publishDate} • {item.author}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Link>
                      </>
                    )}
                  </>
                ))}
              </>
            ) : (
              <div>No Search Kb</div>
            )}
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}></CustomTabPanel>
        <CustomTabPanel value={value} index={2}></CustomTabPanel>
        <CustomTabPanel value={value} index={3}></CustomTabPanel> */}
      </div>
    </>
  );
};

export default KbList;
