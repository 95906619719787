import { createStore, createStyleSet } from 'botframework-webchat';
import classNames from 'classnames';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { Middleware } from 'redux';
import WebChat from './WebChat';
import { appConfig } from '../../../utils/app.config';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// import './fabric-icons-inline.css';
import './MinimizableWebChat.css';

function MinimizableWebChat({ bot = "B0012" }) {
  const [loaded, setLoaded] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const [newMessage, setNewMessage] = useState(false);
  const [side, setSide] = useState('right');
  const [token, setToken] = useState<string>('');
  const [isExpanded, setIsExpanded] = useState(false);

  // Middleware for Redux store
  const middleware: Middleware = ({ dispatch }) => (next) => (action: any) => {
    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
      dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'webchat/join',
          value: {
            language: window.navigator.language
          }
        }
      });
    } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
      if (action.payload.activity.from.role === 'bot') {
        setNewMessage(true);
      }
    }

    return next(action);
  };

  const store = useMemo(() => createStore({}, middleware), []);

  const styleSet = useMemo(() => createStyleSet({
    backgroundColor: 'Transparent'
  }), []);

  //   styleSet.textContent = {
  //     ...styleSet.textContent,
  //     fontFamily: "'Comic Sans MS', 'Arial', sans-serif",
  //     fontWeight: 'bold'
  //  };
  const styleOptions = useMemo(() => (
    {

      backgroundColor: 'Transparent',
      botAvatarImage: './static/img/logos/bot-logo-05.svg',
      botAvatarBackgroundColor: 'rgba(0, 0,255, .1)',
      //    backgroundColor: 'rgba(0, 0,255, .1)',
      botAvatarInitials: 'BF',
      bubbleNubSize: 10,
      bubbleBorderRadius: 10,
      bubbleNubOffset: 6,
      bubbleBackground: 'rgba(0, 0, 255, .1)',
      bubbleFromUserBackground: 'rgba(0, 0,255, .1)',
      userAvatarImage: './static/img/logos/bot-logo-05.svg',
      userAvatarInitials: 'WC',
      userAvatarBackgroundColor: '#F77',
      bubbleFromUserBorderRadius: 10,
      bubbleFromUserNubOffset: 6,
      bubbleFromUserNubSize: 6,
      avatarSize: 40,
      emojiSet: {
        ':sheep:': '🐑',
        '<3': '❤️'
      },
      hideUploadButton: true

    }
  ), []);
  const handleFetchToken = useCallback(async () => {
    const authToken = sessionStorage.getItem('cva.jwt.access.token');
    const requestBody = {
      "user": {
        "id": "vijay.panwar@rr.com",
        "name": "Vijay Panwar"
      },
      "trustedOrigins": [
        "https://aabbb.com"
      ]
    };
    if (!token) {
      const res = await fetch(
        `${appConfig.baseUrl}/api/v2.0/litebot/tokens/generate?botId=${bot}&domainType=cvadirectline`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
            'Content-Type': 'application/json'      // Optional: Set content type to JSON
          },
          body: JSON.stringify(requestBody)
        }
      );
      const { token } = await res.json();
      console.log("----------token:", token)
      setToken(token);
    }
  }, [setToken, token]);

  const handleMaximizeButtonClick = useCallback(() => {
    setLoaded(true);
    setMinimized(false);
    setNewMessage(false);
  }, []);

  const handleMinimizeButtonClick = useCallback(() => {
    setMinimized(true);
    setNewMessage(false);
  }, []);

  const handleExpandButtonClick = useCallback(() => {
    setIsExpanded(!isExpanded); // Toggle expansion
  }, [isExpanded]);

  const handleSwitchButtonClick = useCallback(() => {
    setSide(side === 'left' ? 'right' : 'left');
  }, [side]);

  return (
    <div className="minimizable-web-chat">
      {minimized && (
        <button className="maximize" onClick={handleMaximizeButtonClick} type="button">
          <i className={token ? 'bi-chat-square text-success' : 'bi-chat-square '} />
          {newMessage && <i className="bi-brightness-high-fill text-danger red-dot" />}
        </button>
      )}
      {loaded && (
        <div
          className={classNames(
            side === 'left' ? 'chat-box left' : 'chat-box right',
            minimized ? 'hide' : '',
            isExpanded ? 'expanded' : 'collapse' // Apply expanded class when expanded
          )}
        >

          <header>
            <div className="chat-brand-logo">
              <img src={appConfig.imagesUrl + "/logos/bot-logo-md.png"} alt="Company Logo" />
              <div className="chat-brand-txt"><span>CVA</span></div>
            </div>

            <div className="filler" />

            <button className="switch" onClick={handleSwitchButtonClick} type="button">
              <i className={side === 'right' ? 'bi-align-start' : 'bi-align-end'} ></i>
            </button>
            {/* Expand button */}
            <button className="expand" onClick={handleExpandButtonClick} type="button">
              <i className={!isExpanded ? 'bi-arrows-angle-expand' : 'bi-arrows-angle-contract'} ></i>
            </button>
            <button className="minimize" onClick={handleMinimizeButtonClick} type="button">
              <i className="bi-dash-lg" />
            </button>
          </header>
          <WebChat
            className="react-web-chat"
            onFetchToken={handleFetchToken}
            store={store}
            styleOptions={styleOptions}
            styleSet={styleSet}
            token={token}
          />
        </div>
      )}
    </div>
  );
}

export default memo(MinimizableWebChat);
