import React, { useState } from 'react';
import "./ErrorPopup.css"
import { appConfig } from '../../../../../../utils/app.config';


interface ErrorPopupProps {
    error_msg: string
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ error_msg }) => {

    const handleClick = () => {
        console.log('OK CLICKED ')
    }

    return (
        <>

            <div className='error-pop-up'>
                <div className='error-pop-up-container'>
                    <div className="error-pop-up-icon">
                        <img src={appConfig.imagesUrl + '/desktop/error_icon.png'}></img>
                    </div>
                    <div className="error-pop-up-text">
                        <p>{error_msg}</p>
                    </div>
                    <button className='error-pop-up-button' onClick={handleClick}>
                        <img src={appConfig.imagesUrl + '/desktop/check-circle.png'}></img>
                        Okay
                    </button>
                </div>
            </div>

        </>
    );
};

export default ErrorPopup;
