import React, { useState } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';  // Import Bootstrap Icons CSS
import { appConfig } from '../../../utils/app.config';
import { useSelector } from 'react-redux';

const ResetPassword: React.FC = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState<string | null>(null);
    const [currentPasswordError, setCurrentPasswordError] = useState(false); // Track if current password is missing
    const userProfile = useSelector((store: any) => store.login.loginDetails);

    const passwordPolicy = {
        minLength: 8,
        hasUpperCase: /[A-Z]/,
        hasLowerCase: /[a-z]/,
        hasNumber: /\d/,
        hasSpecialChar: /[@$!%*?&#]/,
    };

    const validatePassword = () => {
        const errors = [];
        if (password.length < passwordPolicy.minLength) errors.push('At least 8 characters');
        if (!passwordPolicy.hasUpperCase.test(password)) errors.push('At least one uppercase letter');
        if (!passwordPolicy.hasLowerCase.test(password)) errors.push('At least one lowercase letter');
        if (!passwordPolicy.hasNumber.test(password)) errors.push('At least one digit');
        if (!passwordPolicy.hasSpecialChar.test(password)) errors.push('At least one special character');
        return errors;
    };

    const passwordErrors = validatePassword();
    const isPasswordValid = passwordErrors.length === 0;

    const getresetPassword = async () => {
        try {
            const token = sessionStorage.getItem(appConfig.loginTokenName);
            console.log(token)
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: userProfile['raw']['raw']['mail'],
                    newPassword: confirmPassword,
                    currentPassword: currentPassword
                })
            };
            const result = await (await fetch(`${appConfig.baseUrl}/auth/resetPassword/oauth2/cdd`, options)).json();
            if (result) {
                if (result.status === 200) {
                    setMessage('Password reset successfully!');
                    setTimeout(async () => {
                        try {
                            sessionStorage.removeItem('cva.jwt.access.token')
                            const options = {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            };
                            const result = await (await fetch(`${appConfig.baseUrl}/auth/login/signout`, options)).json();
                            window.location.href = '/ui'
                        } catch (err) {
                            console.log('ERROR :::', err);
                            sessionStorage.removeItem('cva.jwt.access.token')
                        }
                    }, 3000)
                }
                if (result.status === 400) {
                    setMessage(result.message);
                }
            }
        } catch (err) {
            console.log('ERROR :::', err);
            setMessage('Please try again after sometime.');
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!currentPassword) {
            setCurrentPasswordError(true);
            return;
        } else {
            setCurrentPasswordError(false);
        }

        if (isPasswordValid && password === confirmPassword) {
            getresetPassword();
        } else if (!isPasswordValid) {
            setMessage('Password does not meet the requirements.');
        } else {
            setMessage('Passwords do not match!');
        }
    };

    const renderValidationIcon = (isValid: boolean) => {
        return isValid ? (
            <i className="bi bi-check-circle-fill" style={{ color: 'green', marginRight: '8px' }}></i>
        ) : (
            <i className="bi bi-x-circle-fill" style={{ color: 'red', marginRight: '8px' }}></i>
        );
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!currentPassword) {
            setCurrentPasswordError(true);
        } else {
            setPassword(e.target.value);
            setCurrentPasswordError(false);
        }
    };

    return (
        <div className="d-flex justify-content-center mt-2">
            <div className="card shadow-sm p-4" style={{ maxWidth: '500px', width: '100%', color: 'var(--header-text-color)' }}>
                <div>
                    <p style={{ textAlign: 'center', fontSize: '24px', fontWeight: 500, marginBottom: '15px' }}>
                        <i className="bi bi-key" style={{ marginRight: '8px' }}></i>
                        Reset Password
                    </p>
                    <Form onSubmit={handleSubmit}>
                        {/* Current Password Field */}
                        <Form.Group controlId="formCurrentPassword" className="mb-3">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter current password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                required
                                isInvalid={currentPasswordError}  // Add isInvalid state
                            />
                            {currentPasswordError && (
                                <Form.Text className="text-danger">
                                    Please enter your current password.
                                </Form.Text>
                            )}
                        </Form.Group>

                        {/* New Password Field */}
                        <Form.Group controlId="formPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter new password"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                                isValid={password.length > 0 && isPasswordValid}
                                isInvalid={password.length > 0 && !isPasswordValid}
                                disabled={!currentPassword}  /* Disable if current password is not provided */
                            />

                            {/* Conditionally render the password policy only if user starts typing and current password is provided */}
                            {password && currentPassword && (
                                <Form.Text>
                                    <div className="mt-2">
                                        <p>Password must meet the following requirements:</p>
                                        <ul style={{ listStyle: 'none', paddingLeft: '0' }}>
                                            <li>
                                                {renderValidationIcon(password.length >= passwordPolicy.minLength)}
                                                At least 8 characters
                                            </li>
                                            <li>
                                                {renderValidationIcon(passwordPolicy.hasUpperCase.test(password))}
                                                At least one uppercase letter
                                            </li>
                                            <li>
                                                {renderValidationIcon(passwordPolicy.hasLowerCase.test(password))}
                                                At least one lowercase letter
                                            </li>
                                            <li>
                                                {renderValidationIcon(passwordPolicy.hasNumber.test(password))}
                                                At least one digit
                                            </li>
                                            <li>
                                                {renderValidationIcon(passwordPolicy.hasSpecialChar.test(password))}
                                                At least one special character (@, $, !, %, *, ?, &, #)
                                            </li>
                                        </ul>
                                    </div>
                                </Form.Text>
                            )}
                        </Form.Group>

                        {/* Confirm Password Field */}
                        <Form.Group controlId="formConfirmPassword" className="mt-3">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Confirm new password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                isValid={confirmPassword.length > 0 && confirmPassword === password}
                                isInvalid={confirmPassword.length > 0 && confirmPassword !== password}
                                disabled={!currentPassword}  /* Disable if current password is not provided */
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="my-4" disabled={!isPasswordValid}>
                            Reset Password
                        </Button>
                        {message && (
                            <Alert variant={message.includes('successfully') ? 'success' : 'danger'}>
                                {message}
                            </Alert>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
