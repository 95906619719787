import React, { useEffect, useState } from 'react';
import { Nav, Container, Form, Button, Row, Col, Card} from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import "./performance.css";
import { useTheme } from '@mui/material';



const Performance: React.FC = () => {

    let theme = useTheme();
    let color = theme.palette.mode;

    const gaugeData = {
        labels: ["Used", "Free"],
        datasets: [
            {
                data: [20, 80],
                backgroundColor: ["red", "grey", "yellow"],
                hoverBackgroundColor: ["red", "grey","yellow"],
                borderWidth: 0,
            },
        ],
    };

    const gaugeOptions = {
        cutout: "70%",
        rotation: -90,
        circumference: 180,
        plugins: {
            legend: {
                display: true
            },
            tooltip: {
                enabled: true,
            },
        },
    };



    return (
        <Container fluid className='pb-4'>
            <h5 className={`${color == 'dark' ? 'header-dark' : ''}`}>Performance</h5>
            <Row>
                <Col md={4} className='pb-2'>
                    <Card text={color==="dark" ? 'white': 'dark'} className='card-size'>
                    <span className='same-line header-text'>CPU Utilization</span>
                        <div className='d-flex flex-column align-items-center'>
                            <Doughnut data={gaugeData} options={gaugeOptions} />
                            <span className='bold-text'>100%</span>
                            <span>Processor Queue</span>
                            
                        {/* <div className='d-flex justify-content-between w-100 mt-2 px-4'>
                            <span>0</span>
                            <span>25</span>
                            <span>50</span>
                            <span>75</span>
                            <span>100</span>
                        </div> */}
                        </div>
                    </Card>
                </Col>
                <Col md={4} className='pb-2'>
                    <Card text={color==="dark" ? 'white': 'dark'}>
                        <span className='same-line header-text'>Memory Utilization</span>
                        <div className='d-flex flex-column align-items-center'>
                            <Doughnut data={gaugeData} options={gaugeOptions} />
                            <span className='bold-text'>100%</span>
                            <span>Trans Faults Ration(%)</span>
                            
                        {/* <div className='d-flex justify-content-between w-100 mt-2 px-4'>
                            <span>0</span>
                            <span>25</span>
                            <span>50</span>
                            <span>75</span>
                            <span>100</span>
                        </div> */}
                        </div>
                    </Card>
                </Col>
                <Col md={4} className='pb-2'>
                    <Card text={color==="dark" ? 'white': 'dark'}>
                    <span className='same-line header-text'>Disk Activity</span>
                        <div className='d-flex flex-column align-items-center'>
                            <Doughnut data={gaugeData} options={gaugeOptions} />
                            <span className='bold-text'>100%</span>
                            <span>Free Space(%)</span>
                            
                        {/* <div className='d-flex justify-content-between w-100 mt-2 px-4'>
                            <span>0</span>
                            <span>25</span>
                            <span>50</span>
                            <span>75</span>
                            <span>100</span>
                        </div> */}
                        </div>
                        
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Performance;