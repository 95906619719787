import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './error.css';

const Error401: React.FC = () => {
    return (
        <div className="container d-flex justify-content-center align-items-center vh-100">
            <div className="error-page text-center">
                <h1 className="display-4 text-danger">401 - Unauthorized</h1>
                <p className="lead">You do not have permission to view this page.</p>
            </div>
        </div>
    );
};

export default Error401;
