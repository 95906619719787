
import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux';
import { HeadersState, setDefaultUserProfileItemsOverride, setHeadersDynamicButtons, setRedirectUrl, setUserProfileItems } from '../../../utils/redux-store/slices/headers.slice';
import { useLocation } from 'react-router-dom';
import { HeaderButton } from '../headers/headers';
import { DropdownLinks } from '../drop-down/drop-down.menu';



interface HeadersProps {
    redirectUrl?: string;
    headersDynamicButtons?: HeaderButton[]; // Array of buttons to be dynamically rendered
    userProfileItems?: Array<DropdownLinks>; // Array of user-provided items
    defaultUserProfileItemsOverride?: Array<Partial<DropdownLinks>>;
}

const UpdateHeaders: React.FC<HeadersProps> = ({ redirectUrl, headersDynamicButtons, userProfileItems, defaultUserProfileItemsOverride }) => {

    const dispatch = useDispatch();
    const location = useLocation(); // Get the current route location

    useEffect(() => {
        if (redirectUrl)
            dispatch(setRedirectUrl(redirectUrl))

        if (headersDynamicButtons)
            dispatch(setHeadersDynamicButtons(headersDynamicButtons))

        if (userProfileItems)
            dispatch(setUserProfileItems(userProfileItems))

        if (defaultUserProfileItemsOverride)
            dispatch(setDefaultUserProfileItemsOverride(defaultUserProfileItemsOverride))
    }, [])

    return null;
}

export default UpdateHeaders