import React, { useContext } from 'react';
import { appConfig } from '../../../utils/app.config';
import { Link } from 'react-router-dom';
import CallMe from '../../../modules/apps/desktop-app/components/Popups/CallMe/CallMe';
import { useTheme } from '@mui/material/styles'; // Import useTheme from MUI
import { ColorModeContext, tokens } from '../../../theme';

interface ProfileProps {
    redirectUrl?: string;
    linkItems?: Array<DropdownLinks>;
}

export interface DropdownLinks {
  id: string;
  icon?: string;
  text?: string;
  link?: string;
  disabled?: boolean;
  position?: number;
  content?: React.ReactNode;
  onClick?: () => void;
  component?: (open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>) => React.ReactNode;
}

const DropDownMenu: React.FC<ProfileProps> = ({ redirectUrl, linkItems = [] }) => {
  redirectUrl = redirectUrl || "/"; // Default value
  const [openCallMe, setOpenCallMe] = React.useState(false);
  const colorMode = useContext(ColorModeContext); // Access color mode context
  const theme = useTheme(); // Access the current theme
  const colors = tokens(theme.palette.mode);

  // Default dropdown items
  const defaultItems: DropdownLinks[] = [
    {
      id: "ticket-status",
      icon: 'bi bi-ticket-perforated',
      text: 'Ticket Status',
      disabled: false,
      link: `${redirectUrl}/desktop/ticket-status`,
      position: 1,
    },
    {
      id: "theme",
      icon: `bi ${theme.palette.mode === 'dark' ? 'bi-sun' : 'bi-moon'}`,
      text: 'Change Theme',
      disabled: false,
      onClick: () => colorMode.toggleColorMode(),
      position: 2,
    },
    {
      id: "profile",
      icon: 'person-bounding-box',
      text: 'My Profile',
      link: `${redirectUrl}/user-profile`,
      disabled: false,
      position: 3,
    },
    {
      id: "about",
      icon: 'info-circle',
      text: 'About CVA',
      link: '#',
      disabled: true,
      position: 4,
    },
    {
      id: "policy",
      icon: 'lock',
      text: 'Privacy Policy',
      link: `${redirectUrl}/privacy-policy`,
      disabled: false,
      position: 5,
    },
    {
      id: "help",
      icon: 'question-circle',
      text: 'Help',
      link: '#',
      disabled: true,
      position: 6,
    },
    {
      id: "agent",
      icon: 'telephone',
      text: 'Connect to Agent',
      onClick: () => setOpenCallMe(true),
      component: (openCallMe, setOpenCallMe) => (
        <CallMe open={openCallMe} onClose={() => setOpenCallMe(false)} />
      ),
      disabled: false,
      position: 7,
    },
    {
      id: "chat",
      icon: 'chat-left',
      text: 'Chat',
      link: `${redirectUrl}/desktop/live-agent`,
      disabled: false,
      position: 8,
    },
    {
      id: "ticket",
      icon: 'ticket-detailed',
      text: 'Raise Ticket',
      link: `${redirectUrl}/desktop/raise-ticket`,
      disabled: false,
      position: 9,
    },
    {
      id: "resetPassword",
      icon: "bi bi-key",
      text: 'Reset Password',
      disabled: false,
      link: `${redirectUrl}/reset-password`,
      position: 10,
    },
    {
      id: "settings",
      icon: "bi bi-gear",
      text: 'Settings',
      disabled: false,
      link: `${redirectUrl}/desktop/settings`,
      position: 11,
    },
    {
      id: "logout",
      icon: 'arrow-bar-right',
      text: 'Logout',
      onClick: async () => {
        console.log('Logout Clicked');
        try {
          sessionStorage.removeItem('cva.jwt.access.token');
          const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          };
          const result = await fetch(`${appConfig.baseUrl}/auth/login/signout`, options).then(res => res.json());
          window.location.href = '/ui';
        } catch (err) {
          console.log('ERROR :::', err);
          sessionStorage.removeItem('cva.jwt.access.token');
        }
      },
      disabled: false,
      position: 12,
    },
  ];

  const mergeItems = (defaultItems: DropdownLinks[], linkItems: DropdownLinks[]): DropdownLinks[] => {
    const linkItemIds = linkItems.map(item => item.id);
    let mergedItems = defaultItems.filter(defaultItem => linkItemIds.includes(defaultItem.id));
    linkItems.forEach(linkItem => {
      const index = mergedItems.findIndex(defaultItem => defaultItem.id === linkItem.id);
      if (index !== -1) {
        mergedItems[index] = { ...mergedItems[index], ...linkItem };
      } else {
        mergedItems.push(linkItem);
      }
    });

    return mergedItems;
  };

  // Combine, filter, and sort the final list of dropdown items by position
  const finalItems = mergeItems(defaultItems, linkItems).sort((a, b) => (a.position || 0) - (b.position || 0));

  return (
    <ul className="dropdown-menu">
      {finalItems.map((item, index) => (
        <li key={index}>
          <Link
            className={`dropdown-item ${item.disabled ? 'disabled' : ''}`}
            to={item.link || '#'}
            role={item.disabled ? 'button' : undefined}
            aria-disabled={item.disabled ? 'true' : undefined}
            onClick={item.disabled ? undefined : item.onClick}
          >
            {item.icon && <i className={`bi-${item.icon} me-2`}></i>}
            <span>{item.text}</span>
          </Link>

          {item.component ? item.component(openCallMe, setOpenCallMe) : null}
        </li>
      ))}
    </ul>
  );
};

export default DropDownMenu;
