import React from 'react';
import { Outlet } from 'react-router-dom';
import Headers, { HeaderButton } from '../commons/headers/headers';
import Footer from '../commons/footer/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import SideMenu from '../commons/side-menu/side-menu';


interface MenuLayoutProps {
    menuOpen: boolean;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

// Separate Layout component for main pages
export const MenuLayout: React.FC<MenuLayoutProps> = ({ menuOpen, setMenuOpen }) => {

    return (
        <>
            <SideMenu isOpen={menuOpen} toggleMenu={() => setMenuOpen(!menuOpen)} />
            <div className="layout-right">
                <Headers isOpen={menuOpen} />
                <main className="main-content">
                    <Outlet />
                    <Footer />

                </main>
                {/* <Footer /> */}
            </div>
        </>
    );
};

