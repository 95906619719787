import React, { useEffect, useState } from "react";
import "./KbArticle.css";
import { useLocation } from "react-router";
import { Rating } from "@mui/material";
import BackButton from "../../../../../application/commons/back-button/back-button";


const KbArticle: React.FC = () => {
    const location = useLocation();

    const { passedKbArticle } = location.state || {};
    console.log("passed kb Article", passedKbArticle)

    return (
        <>
        <div className="kbArticleWrapper">
            <div className="row1">
                <div id="back" className="back">
                <BackButton></BackButton>
                </div>
                <div style={{fontSize: '16px', fontWeight: '600'}}>
                    {passedKbArticle?.title}
                </div>

            </div>
            <div style={{padding: '0px 16px'}}>
            <Rating value={Number(passedKbArticle?.rating)} readOnly />
            </div>
            <div style={{margin: '0px 16px'}} className="contentBox">

            </div>      
        </div>
           
        </>
    )

}

export default KbArticle ;