import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HeaderButton } from '../../../application/commons/headers/headers';
import { DropdownLinks } from '../../../application/commons/drop-down/drop-down.menu';

export interface HeadersState {
    redirectUrl?: string;
    headersDynamicButtons?: HeaderButton[]; // Array of buttons to be dynamically rendered
    userProfileItems?: DropdownLinks[]; // Array of user profile items
    defaultUserProfileItemsOverride?: Partial<DropdownLinks>[]; // Allow partial customization of default user profile items
}

const initialState: HeadersState = {
    redirectUrl: "/",
    headersDynamicButtons: [],
    userProfileItems: [],
    defaultUserProfileItemsOverride: [],
};


const headersSlice = createSlice({
    name: 'headers',
    initialState,
    reducers: {
        setRedirectUrl(state, action: PayloadAction<string>) {
            state.redirectUrl = action.payload;
        },
        setHeadersDynamicButtons(state, action: PayloadAction<HeaderButton[]>) {
            state.headersDynamicButtons = action.payload;
        },
        setUserProfileItems(state, action: PayloadAction<DropdownLinks[]>) {
            state.userProfileItems = action.payload;
        },
        setDefaultUserProfileItemsOverride(state, action: PayloadAction<Partial<DropdownLinks>[]>) {
            state.defaultUserProfileItemsOverride = action.payload;
        },
    },
});


export const {
    setRedirectUrl,
    setHeadersDynamicButtons,
    setUserProfileItems,
    setDefaultUserProfileItemsOverride,
} = headersSlice.actions;

export default headersSlice.reducer;
