import React, { useEffect, useRef, useState } from "react";
import "./main.css";
import SearchBar from "../components/SearchBar/SearchBar";
import { useSelector } from "react-redux";
import AppsList from "../components/AppsList/AppsList";
import Catalog from "../components/AppsList/Catalog/Catalog";
import Outages from "../components/Outages/Outages";
import Queries from "../components/Queries/Outages";
import ErrorPopup from "../components/Popups/ErrorPopup/ErrorPopup";
import CustomTabs from "../components/CustomTabs/CustomTabs";

interface MainProps {
  redirectUrl?: string;
}

const Main: React.FC<MainProps> = ({ redirectUrl }) => {
  const userProfile = useSelector((store: any) => store.login.loginDetails);
  const recentSearches = useSelector(
    (store: any) => store.search.recentSearches
  );
  const trendingSearches = useSelector(
    (store: any) => store.search.trendingSearches
  );
  const [activeTab, setActiveTab] = useState<string>("Apps"); // Default is none active
  const [indicatorStyle, setIndicatorStyle] = useState<React.CSSProperties>({}); // Style for the indicator
  const buttonsRef = useRef<(HTMLDivElement | null)[]>([]); // Store refs to buttons
  const [tokenExpired, setTokenExpired] = useState(false);

  // useEffect(() => {
  //     const timer = setTimeout(() => setTokenExpired(true), 2000);
  //     // Cleanup the timeout on component unmount
  //     return () => clearTimeout(timer);
  // }, [])

  const handleButtonClick = (tabName: string, index: number) => {
    setActiveTab(tabName); // Update the active tab
    const button = buttonsRef.current[index]; // Get the clicked button element
    console.log(buttonsRef.current[index]);

    if (button) {
      // Set the width and left position of the indicator
      setIndicatorStyle({
        width: `${button.offsetWidth}px`,
        left: `${button.offsetLeft}px`,
      });
    }
  };

  useEffect(() => {
    // Initially set the indicator to the first button's position (if necessary)
    if (buttonsRef.current[0]) {
      setIndicatorStyle({
        width: `${buttonsRef.current[0]!.offsetWidth}px`,
        left: `${buttonsRef.current[0]!.offsetLeft}px`,
      });
    }
  }, []);

  if (tokenExpired) {
    return <ErrorPopup error_msg="Token Expired" />;
  }

  const tabData = [
    {
      name: "Apps",
      content: (
        <div>
          <div className="display_apps">
            <AppsList redirectUrl={redirectUrl} />
          </div>
          <div className="catalog-content-display">
            <Catalog />
          </div>
        </div>
      ),
    },
    {
      name: "List of Outages",
      content: (
        <div className="catalog-content-display">
          <Outages />
        </div>
      ),
    },
    {
      name: "Top 10 Queries",
      content: (
        <div className="catalog-content-display">
          <Queries />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="main-display">
        <div className="title-main">
          <h1 className="heading">CVA APP</h1>
          <p className="welcome-msg">
            Hi {userProfile["given_name"]}, How can I help you?
          </p>
        </div>
        <div className="main-searchbar">
          <SearchBar
            trendingSearches={trendingSearches}
            recentSearches={recentSearches}
          />
        </div>
      </div>

      <CustomTabs data={tabData}/>

      {/* <div className="desktop-main-content">
        <div className="desktop-main-content-display">
          <div
            className="desktop-main-content-buttons"
            ref={(el) => (buttonsRef.current[0] = el)}
            onClick={() => handleButtonClick("Apps", 0)}
          >
            Apps
          </div>
          <div
            className="desktop-main-content-buttons"
            ref={(el) => (buttonsRef.current[1] = el)}
            onClick={() => handleButtonClick("Outages", 1)}
          >
            List of Outages
          </div>
          <div
            className="desktop-main-content-buttons"
            ref={(el) => (buttonsRef.current[2] = el)}
            onClick={() => handleButtonClick("Queries", 2)}
          >
            Top 10 Queries
          </div>
          <div className="horizontal-indicator" style={indicatorStyle}></div>
        </div>
      </div> */}
      {/* Conditional rendering based on the active tab */}
      {/* <div className="content-display">
        {activeTab === "Apps" && (
          <div>
            <div className="display_apps">
              <AppsList redirectUrl={redirectUrl} />
            </div>
            <div className="catalog-content-display">
              <Catalog />
            </div>
          </div>
        )}

        {activeTab === "Outages" && (
          <div className="catalog-content-display">
            <Outages />
          </div>
        )}

        {activeTab === "Queries" && (
          <div className="catalog-content-display">
            <Queries />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default Main;
