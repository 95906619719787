import React from 'react';
import './loginSelection.css'; // Import the CSS file for styling
import 'bootstrap/dist/css/bootstrap.min.css';

const LoginSelection: React.FC = () => {
    return (
        <div className="login-selection-container container d-flex flex-column justify-content-center align-items-center vh-100">
            {/* Project name at the top */}
            <div className="login-text text-center mb-4">
                <h1>Login</h1> {/* Change "Project Name" to your actual project name */}
            </div>

            {/* Company logo in center */}
            <div className="company-logo-container text-center mb-4">
                <div className="company-logo">
                    <img src="static/img/logos/bot-logo-md.png" alt="Company Logo" />
                </div>
            </div>

            {/* Separator with project name */}
            <div className="separator text-center separator-project">
                <h1 className="project-name">cognitive virtual assistant</h1> {/* Change "Project Name" to your actual project name */}
            </div>

            {/* Service providers */}
            <div className="service-providers d-flex justify-content-around w-90 mt-4">
                <div className="provider text-center">
                    <a href="https://www.microsoft.com" className="provider-link">
                        <div className="provider-logo">
                            <img src="static/img/brand_logos/microsoft_black.svg" alt="Microsoft Logo" />
                        </div>
                        <p>Microsoft</p>
                    </a>
                </div>
                <div className="provider text-center">
                    <a href="https://www.google.com" className="provider-link">
                        <div className="provider-logo">
                            <img src="static/img/brand_logos/google_black.svg" alt="Google Logo" />
                        </div>
                        <p>Google</p>
                    </a>
                </div>
                <div className="provider text-center">
                    <a href="https://www.okta.com" className="provider-link">
                        <div className="provider-logo">
                            <img src="static/img/brand_logos/octa_black.svg" alt="Okta Logo" />
                        </div>
                        <p>Okta</p>
                    </a>
                </div>
                <div className="provider text-center">
                    <a href="http://localhost:8082/auth/login/oauth2?provider=4&state=B0001" className="provider-link">
                        <div className="provider-logo">
                            <img src="static/img/brand_logos/bot-logo-05.svg" alt="PingID Logo" />
                        </div>
                        <p>CVA</p>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LoginSelection;
