import React from 'react';
import './back-button.css';
import { useNavigate } from "react-router-dom";

const BackButton: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="back_button_container" onClick={() => navigate(-1)}>
                <div className="back_button_left_arrow">
                    <div className="back_button_image"></div>
                </div>
                <div className="back_button_text">
                    Back
                </div>
            </div>
        </>
    )
}

export default BackButton;