import { useNavigate } from 'react-router-dom';
import React, { memo, useEffect, useMemo } from 'react'; 

 const SomeComponent: React.FC = () => {
    const navigate = useNavigate();

    // Example function to redirect based on unauthorized access
    const handleUnauthorizedAccess = () => {
        // Redirect to 401 page
        navigate('/error401');
    };

    return (
        <div>
            <button onClick={handleUnauthorizedAccess}>Simulate 401 Error</button>
        </div>
    );
};

export default SomeComponent;