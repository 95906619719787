import React, { useEffect, useState } from 'react';
import './UserProfile.css'; // Add your styles here
import { useSelector } from 'react-redux';
import { getUserProfilePictureUrl, updateUserProfileUrl } from '../../../utils/constants/constants';
import { appConfig } from '../../../utils/app.config';
import CameraModal from './camera-modal/camera-modal';


const UserProfile: React.FC = () => {
    const userProfile = useSelector((store: any) => store.login.loginDetails);
    const basicDetails = userProfile['raw']['raw'];
    const [isModalOpen, setModalOpen] = useState(false);
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);
    const [getprofilePic, setGetProfilePic] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null);
    const [profileUpdatedMessage, setProfileUpdatedMessage] = useState<string | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [updatedProfile, setUpdatedProfile] = useState({
        firstName: basicDetails['givenName'] || '',
        lastName: basicDetails['family_name'] || '',
        email: basicDetails['mail'] || '',
        phone: basicDetails['mobilePhone'] || '',
        officeLocation: basicDetails['officeLocation'] || '',
        language: basicDetails['preferredLanguage'] || '',
        jobTitle: basicDetails['jobTitle'] || '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUpdatedProfile(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSaveProfile = () => {
        // Logic to save the updated profile can be added here
        // For now, just toggle editMode and log updatedProfile
        console.log("Updated Profile:", updatedProfile);
        setEditMode(false);
        sendUpdatedProfileData()
    };

    useEffect(() => {
        const getProfilePicture = async () => {
            try {
                const token = sessionStorage.getItem(appConfig.loginTokenName);
                console.log('[FETCH TOKEN CALLED]')
                const response = await (await fetch(`${getUserProfilePictureUrl}?email=${updatedProfile.email}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`
                    }
                })).json();

                if (response['status'] === 200) {
                    console.log("Profile Picture successfully.");
                    setGetProfilePic(response['profilePicture'])
                } else {
                    console.error("Error updating profile.");
                    setError(response['message'])
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
        getProfilePicture();
    }, [])


    const sendUpdatedProfileData = async () => {
        try {
            const token = sessionStorage.getItem(appConfig.loginTokenName);

            const response = await (await fetch(updateUserProfileUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    email: updatedProfile.email,
                    displayName: updatedProfile.firstName + ' ' + updatedProfile.lastName,
                    givenName: updatedProfile.firstName,
                    family_name: updatedProfile.lastName,
                    jobTitle: updatedProfile.jobTitle,
                    mobilePhone: updatedProfile.phone,
                    officeLocation: updatedProfile.officeLocation,
                    preferredLanguage: updatedProfile.language,
                })
            })).json();

            if (response['status'] === 200) {
                console.log("Profile Updated successfully.");
                sessionStorage.removeItem(appConfig.loginTokenName);
                setProfileUpdatedMessage(response['message'])
            } else {
                console.error("Error updating profile.");
                setError(response['message'])
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <div className="desktop_userprofile_main_div">
                <div className="desktop_userprofile_container">
                    <div className="desktop_userprofile_row1">
                        <div>
                            <img src="/ui/static/img/desktop/Icon feather-user.png" alt="profile" />
                        </div>
                        <div className="desktop_userprofile_info">Profile</div>
                    </div>

                    <div className="desktop_userprofile_container2">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div className="desktop_userprofile_rectangle">
                                {
                                    !getprofilePic ? <div className="desktop_userprofile_circle">
                                        <div className="desktop_userprofile_initials" id="img">
                                            {basicDetails['givenName'][0] + basicDetails['family_name'][0]}
                                        </div>
                                    </div> :
                                        <div className="desktop_userprofile_container_profile_pic">
                                            <img className="desktop_userprofile_profile_pic" src={getprofilePic} alt="Profile" />
                                        </div>
                                }

                                <div className="desktop_userprofile_overlay">
                                    <i
                                        className="bi bi-camera upload-button"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setUploadModalOpen(true)}
                                    ></i>
                                </div>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className="desktop_userprofile_name">{basicDetails['displayName']}</div>
                                <div className="desktop_userprofile_designation">
                                    {updatedProfile['jobTitle']}
                                </div>
                            </div>
                        </div>
                        <div className="desktop_userprofile_general">First Name:</div>
                        {editMode ? (
                            <input
                                type="text"
                                name="firstName"
                                value={updatedProfile.firstName}
                                onChange={handleInputChange}
                                className="desktop_userprofile_input"
                            />
                        ) : (
                            <div className="desktop_userprofile_maintext">{updatedProfile.firstName}</div>
                        )}
                        <hr />
                        <div className="desktop_userprofile_general">Last Name:</div>
                        {editMode ? (
                            <input
                                type="text"
                                name="lastName"
                                value={updatedProfile.lastName}
                                onChange={handleInputChange}
                                className="desktop_userprofile_input"
                            />
                        ) : (
                            <div className="desktop_userprofile_maintext">{updatedProfile.lastName}</div>
                        )}
                        <hr />

                        <div className="desktop_userprofile_general">Email:</div>
                        <div className="desktop_userprofile_maintext">{updatedProfile.email}</div>
                        <hr />

                        <div className="desktop_userprofile_general">Phone:</div>
                        {editMode ? (
                            <input
                                type="number"
                                name="phone"
                                value={updatedProfile.phone}
                                onChange={handleInputChange}
                                className="desktop_userprofile_input"
                            />
                        ) : (
                            <div className="desktop_userprofile_maintext">{updatedProfile.phone}</div>
                        )}
                        <hr />

                        <div className="desktop_userprofile_general">Job Title:</div>
                        {editMode ? (
                            <input
                                type="text"
                                name="jobTitle"
                                value={updatedProfile.jobTitle}
                                onChange={handleInputChange}
                                className="desktop_userprofile_input"
                            />
                        ) : (
                            <div className="desktop_userprofile_maintext">{updatedProfile.jobTitle}</div>
                        )}
                        <hr />

                        <div className="desktop_userprofile_general">Office Location:</div>
                        {editMode ? (
                            <input
                                type="text"
                                name="officeLocation"
                                value={updatedProfile.officeLocation}
                                onChange={handleInputChange}
                                className="desktop_userprofile_input"
                            />
                        ) : (
                            <div className="desktop_userprofile_maintext">{updatedProfile.officeLocation}</div>
                        )}
                        <hr />

                        <div className="desktop_userprofile_general">Language:</div>
                        {editMode ? (
                            <input
                                type="text"
                                name="language"
                                value={updatedProfile.language}
                                onChange={handleInputChange}
                                className="desktop_userprofile_input"
                            />
                        ) : (
                            <div className="desktop_userprofile_maintext">{updatedProfile.language}</div>
                        )}
                        <hr />

                        <div className="desktop_userprofile_revoke" onClick={() => setModalOpen(true)}>
                            Withdraw your consent.
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {profileUpdatedMessage && <div className="desktop_profile_update_success">{profileUpdatedMessage}</div>}
                            {error && <div className="desktop_profile_pic_error">{error}</div>}
                        </div>
                        <div className="desktop_userprofile_actions">
                            {editMode ? (
                                <>
                                    <button className="btn btn-primary desktop_userprofile_save" onClick={handleSaveProfile}>Save</button>
                                    <button className="btn btn-primary desktop_userprofile_cancel" onClick={() => setEditMode(false)}>Cancel</button>
                                </>
                            ) : (
                                <button className="btn btn-primary desktop_userprofile_edit" onClick={() => setEditMode(true)}>Edit Profile</button>
                            )}
                        </div>
                    </div>
                </div>
                {isModalOpen && <ConfirmationModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} onConfirm={() => setModalOpen(false)} />}
                {isUploadModalOpen && <CameraModal isOpen={isUploadModalOpen} onClose={() => setUploadModalOpen(false)} profilePicture={getprofilePic} setProfilePicture={setGetProfilePic} initials={updatedProfile.firstName[0] + updatedProfile.lastName[0]} email={updatedProfile.email} />}

            </div >
        </>
    );
};

interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content1">
                <div className="modal-header">
                    <h2>Privacy Policy</h2>
                    <button className="close-button" onClick={onClose}>✖</button>
                </div>
                <div className="modal-body">
                    <p>Are you sure you want to withdraw your consent? After confirmation, you will be logged out of the system and your current activity will be affected.</p>
                </div>
                <div className="modal-footer">
                    <button className="confirm-button" onClick={onConfirm}>Yes</button>
                    <button className="cancel-button" onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};



export default UserProfile;
