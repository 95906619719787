import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../../locales/english.json';
import es from '../../locales/spanish.json';
import ja from '../../locales/japanese.json';
import fr from '../../locales/french.json';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        es: { translation: es },
        ja: { translation: ja },
        fr: { translation: fr }
    },
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language if selected language is unavailable
    interpolation: {
        escapeValue: false 
    }
});

export default i18n;
