import React, { useEffect, useState } from 'react';
import { Nav, Container, Form, Button, Row, Col, Card} from 'react-bootstrap';
import "./user-section.css";
import { useTheme } from '@mui/material';


const UserSection: React.FC = () => {

    let theme = useTheme();
    let color = theme.palette.mode;
    
    return (
        <div className={`container`}>
            <Card className='mt-3' text={color==="dark" ? 'white': 'dark'}>
                {/* add card-header class to below div for a bootstrap card header look */}
                <div className={`${color == 'dark' ? 'header-dark' : 'header-color'}`}>
                    <h5>User Information</h5>
                </div>
                <div className='card-body'>
                    <div className='row'>
                         <div className='col'>
                            <p>User ID<br/><strong>Deepu Cyriac</strong></p>
                            <p>User Name<br/><strong>Deepu Cyriac</strong></p>
                         </div>
                         <div className='col'>
                            <p>Email<br/><strong>deepu.cyriac@tcs.com</strong></p>
                            <p>Office Phone<br/><strong>8894323456</strong></p>
                         </div>
                         <div className='col'>
                            <p>External Email<br/><strong>deepu.cyriac@tcs.com</strong></p>
                            <p>Out of Office<br/><strong>18:00 PM</strong></p>
                         </div>
                         <div className='col'>
                            <p>Mobile Phone<br/><strong>9934231211</strong></p>
                            <p>Profile Create Date<br/><strong>21/3/24</strong></p>
                         </div>
                         <div className='col'>
                            <p>Language<br/><strong>English</strong></p>
                         </div>
                    </div>
                    </div>
                </Card> 

                <Card className='mt-3' text={color==="dark" ? 'white': 'dark'}>
                    <div className={`${color == 'dark' ? 'header-dark' : 'header-color'}`}>
                        <h5>Location</h5>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col'>
                                <p>Country<br/><strong>India</strong></p>
                            </div>
                            <div className='col'>
                                <p>City<br/><strong>Kochi</strong></p>
                            </div>
                            <div className='col'>
                                <p>Time Zone<br/><strong>IST</strong></p>
                            </div>
                            <div className='col'>
                                <p>State<br/><strong>Kerala</strong></p>
                            </div>
                            <div className='col'>
                                <p>Zipcode<br/><strong>586423</strong></p>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className='mt-3' text={color==="dark" ? 'white': 'dark'}>
                    <div className={`${color == 'dark' ? 'header-dark' : 'header-color'}`}>
                        <h5>Employment</h5>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col'>
                                <p>Employment Type<br/><strong>Regular</strong></p>
                            </div>
                            <div className='col'>
                                <p>Manager Name<br/><strong>gyan.prakash@tcs.com</strong></p>
                            </div>
                            <div className='col'>
                                <p>Title<br/><strong>Administrator</strong></p>
                            </div>
                            <div className='col'>
                                <p>Cost Center<br/><strong>--</strong></p>
                            </div>
                        </div>
                    </div>
                </Card>

                <Card className='mt-3 mb-3' text={color==="dark" ? 'white': 'dark'}>
                    <div className={`${color == 'dark' ? 'header-dark' : 'header-color'}`}>
                        <h5>User Account</h5>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col'>
                                <p>Account Status<br/><strong>---</strong></p>
                            </div>
                            <div className='col'>
                                <p>VIP Status<br/><strong>---</strong></p>
                            </div>
                            <div className='col'>
                                <p>AD Groups Added<br/><strong>---</strong></p>
                            </div>
                            <div className='col'>
                                <p>AD Sync Status<br/><strong>---</strong></p>
                            </div>
                            <div className='col'>
                                <p>Lockout Status<br/><strong>---</strong></p>
                            </div>
                        </div>
                    </div>
                </Card>

            </div>
    )
}

export default UserSection;