import React, { useEffect, useState } from 'react';
import { Nav, Container, Form, Button, Row, Col} from 'react-bootstrap';
import "./device-section.css";
import Details from './components/Details/details';
import Performance from './components/Performance/performance';
import MostUsedApps from './components/MostUsedApps/most-used-apps';
import OverallExp from './components/OverallExp/overall-exp';


// charts in this section are built using chart.js and react-chartjs-2 libraries
// docs for chart: https://www.chartjs.org/docs/latest/

const DeviceSection: React.FC = () => {

    return (
        <Container className="mt-4">
            <Row>
                <Col md={8}>
                    <Performance />
                </Col>
                <Col md={3}>
                    <Details />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col md={6}>
                    <MostUsedApps />
                </Col>
                <Col md={6}>
                    <OverallExp />
                </Col>
            </Row>
        </Container>
    )
}

export default DeviceSection;