import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define the initial state structure
interface LoginState {
    isAuthenticated: boolean;
    loginDetails: Record<string, any>; 
}

const initialState: LoginState = {
    isAuthenticated: false,
    loginDetails: {},
};

// Create the login slice
const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        // Reducer to update isAuthenticated
        updateIsAuthenticated: (state: LoginState, action: PayloadAction<{ value: boolean }>) => {
            state.isAuthenticated = action.payload.value;
        },
        // Reducer to update loginDetails
        updateLoginDetails: (state: LoginState, action: PayloadAction<{ value: Record<string, any> }>) => {
            state.loginDetails = action.payload.value;
        },
    },
});

// Export actions and reducer
export const { updateIsAuthenticated, updateLoginDetails } = loginSlice.actions;
export default loginSlice.reducer;