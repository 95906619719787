import React from 'react';

export const ViewUser: React.FC = () => {
    return (
        <div>
            <h2>Profile Page</h2>
            <p>View and edit your profile here.</p>
        </div>
    );
};
