import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import Contract from './pages/Contract';


export const GenAIRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<GenAILayout />}>
                <Route index element={<Contract />} />
                <Route path="contract" element={<Contract />} />
            </Route>
        </Routes>
    );
};

const GenAILayout: React.FC = () => {
    return (
        <div>
            <Outlet />
        </div>
    );
};

