import React, { useState } from 'react';
import './Outages.css';

const Outages: React.FC = () => {
    // State to track the active button
    const [activeButton, setActiveButton] = useState('Personal');

    return (
        <div className='outage-main-container'>
            <div className='outage-buttons-container'>
                <div className='outage-Personal-container'>
                    <button
                        className={`outage-button ${activeButton === 'Personal' ? 'active' : ''}`}
                        onClick={() => setActiveButton('Personal')}
                    >
                        Personal Level
                    </button>
                </div>
                <div className='outage-organizational-container'>
                    <button
                        className={`outage-button ${activeButton === 'Organizational' ? 'active' : ''}`}
                        onClick={() => setActiveButton('Organizational')}
                    >
                        Organizational Level
                    </button>
                </div>
            </div>
            {/* Conditional rendering of content based on the active button */}
            <div className='outage-main-content-display'>
                {activeButton === 'Personal' && <div>Personal Level content goes here</div>}
                {activeButton === 'Organizational' && <div>Organizational Level content goes here</div>}
            </div>
        </div>
    );
};

export default Outages;
