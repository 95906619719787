import { createContext, useState, useMemo, ReactNode } from "react";
import { createTheme, Theme } from "@mui/material/styles";

// Define color tokens with types
export const tokens = (mode: "light" | "dark") => ({
    grey: {
        100: mode === "dark" ? "#e0e0e0" /* Light Gray */ : "#141414" /* Rich Black */,
        200: mode === "dark" ? "#c2c2c2" /* Gray */ : "#292929" /* Dark Charcoal */,
        300: mode === "dark" ? "#a3a3a3" /* Muted Gray */ : "#3d3d3d" /* Steel Gray */,
        400: mode === "dark" ? "#858585" /* Dark Gray */ : "#525252" /* Charcoal Gray */,
        500: mode === "dark" ? "#666666" /* Dim Gray */ : "#666666" /* Dim Gray */,
        600: mode === "dark" ? "#525252" /* Charcoal Gray */ : "#858585" /* Dark Gray */,
        700: mode === "dark" ? "#3d3d3d" /* Steel Gray */ : "#a3a3a3" /* Muted Gray */,
        800: mode === "dark" ? "#292929" /* Dark Charcoal */ : "#c2c2c2" /* Gray */,
        900: mode === "dark" ? "#141414" /* Rich Black */ : "#e0e0e0" /* Light Gray */,
    },

    primary: {
        100: mode === "dark" ? "#d0d1d5" /* Light Steel Blue */ : "#040509" /* Deep Black */,
        200: mode === "dark" ? "#a1a4ab" /* Gray Blue */ : "#080b12" /* Almost Black */,
        300: mode === "dark" ? "#727681" /* Muted Blue */ : "#0c101b" /* Gunmetal Black */,
        400: mode === "dark" ? "#1F2A40" /* Dark Slate Blue */ : "#f2f0f0" /* Light Gray */,
        500: mode === "dark" ? "#141b2d" /* Rich Black */ : "#141b2d" /* Rich Black */,
        600: mode === "dark" ? "#101624" /* Charcoal Black */ : "#1F2A40" /* Dark Slate Blue */,
        700: mode === "dark" ? "#0c101b" /* Gunmetal Black */ : "#727681" /* Muted Blue */,
        800: mode === "dark" ? "#080b12" /* Almost Black */ : "#a1a4ab" /* Gray Blue */,
        900: mode === "dark" ? "#040509" /* Deep Black */ : "#d0d1d5" /* Light Steel Blue */,
    },

    greenAccent: {
        100: mode === "dark" ? "#dbf5ee" /* Light Mint Green */ : "#0f2922" /* Dark Forest Green */,
        200: mode === "dark" ? "#b7ebde" /* Mint Green */ : "#1e5245" /* Deep Green */,
        300: mode === "dark" ? "#94e2cd" /* Pale Green */ : "#2e7c67" /* Forest Green */,
        400: mode === "dark" ? "#70d8bd" /* Medium Mint Green */ : "#3da58a" /* Dark Teal */,
        500: mode === "dark" ? "#4cceac" /* Teal */ : "#4cceac" /* Teal */,
        600: mode === "dark" ? "#3da58a" /* Dark Teal */ : "#70d8bd" /* Medium Mint Green */,
        700: mode === "dark" ? "#2e7c67" /* Forest Green */ : "#94e2cd" /* Pale Green */,
        800: mode === "dark" ? "#1e5245" /* Deep Green */ : "#b7ebde" /* Mint Green */,
        900: mode === "dark" ? "#0f2922" /* Dark Forest Green */ : "#dbf5ee" /* Light Mint Green */,
    },

    redAccent: {
        100: mode === "dark" ? "#f8dcdb" /* Light Salmon Pink */ : "#2c100f" /* Dark Crimson */,
        200: mode === "dark" ? "#f1b9b7" /* Salmon Pink */ : "#58201e" /* Rich Red */,
        300: mode === "dark" ? "#e99592" /* Coral Pink */ : "#832f2c" /* Deep Red */,
        400: mode === "dark" ? "#e2726e" /* Light Coral */ : "#af3f3b" /* Dark Red */,
        500: mode === "dark" ? "#db4f4a" /* Crimson Red */ : "#db4f4a" /* Crimson Red */,
        600: mode === "dark" ? "#af3f3b" /* Dark Red */ : "#e2726e" /* Light Coral */,
        700: mode === "dark" ? "#832f2c" /* Deep Red */ : "#e99592" /* Coral Pink */,
        800: mode === "dark" ? "#58201e" /* Rich Red */ : "#f1b9b7" /* Salmon Pink */,
        900: mode === "dark" ? "#2c100f" /* Dark Crimson */ : "#f8dcdb" /* Light Salmon Pink */,
    },

    blueAccent: {
        100: mode === "dark" ? "#e1e2fe" /* Light Lavender */ : "#151632" /* Midnight Blue */,
        200: mode === "dark" ? "#c3c6fd" /* Lavender */ : "#2a2d64" /* Dark Slate */,
        300: mode === "dark" ? "#a4a9fc" /* Periwinkle Blue */ : "#3e4396" /* Slate Blue */,
        400: mode === "dark" ? "#868dfb" /* Light Blue */ : "#535ac8" /* Dark Blue */,
        500: mode === "dark" ? "#6870fa" /* Royal Blue */ : "#6870fa" /* Royal Blue */,
        600: mode === "dark" ? "#535ac8" /* Dark Blue */ : "#868dfb" /* Light Blue */,
        700: mode === "dark" ? "#3e4396" /* Slate Blue */ : "#a4a9fc" /* Periwinkle Blue */,
        800: mode === "dark" ? "#2a2d64" /* Dark Slate */ : "#c3c6fd" /* Lavender */,
        900: mode === "dark" ? "#151632" /* Midnight Blue */ : "#e1e2fe" /* Light Lavender */,
    },
    headers: {
        backround: mode === "dark" ? "black" : "white",
        text: mode === "dark" ? "white" : "black",
        boxShadow: mode === "dark" ? "inset -.2em 0em .8em rgba(255, 255, 255, 0.541)" : "inset -.2em 0em .8em rgb(141 119 119 / 54%)",
    },
    cards: {
        backround: mode === "dark" ? "#121212" : "#fff",
        text: mode === "dark" ? "rgb(169, 169, 169)" : "black",
    }
});


// Define theme settings with types
export const themeSettings = (mode: "light" | "dark") => {
    const colors = tokens(mode);
    return {
        palette: {
            mode: mode,
            ...(mode === "dark"
                ? {
                    primary: {
                        main: colors.primary[500],
                    },
                    secondary: {
                        main: colors.greenAccent[500],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100],
                    },
                    background: {
                        default: colors.primary[500],
                    },
                }
                : {
                    primary: {
                        main: colors.primary[100],
                    },
                    secondary: {
                        main: colors.greenAccent[500],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100],
                    },
                    background: {
                        default: "#fcfcfc",
                    },
                }),
        },
        typography: {
            fontFamily: ["ui-sans-serif", "-apple-system", "system-ui", "Segoe UI", "Helvetica", "Arial", "sans-serif", "Segoe UI Emoji", "Segoe UI Symbol"].join(","),
            fontSize: 12,
            h1: {
                fontSize: 40,
            },
            h2: {
                fontSize: 32,
            },
            h3: {
                fontSize: 24,
            },
            h4: {
                fontSize: 20,
            },
            h5: {
                fontSize: 16,
            },
            h6: {
                fontSize: 14,
            },
        },
    };
};

// Define context type
type ColorModeContextType = {
    toggleColorMode: () => void;
};

// Create the color mode context
export const ColorModeContext = createContext<ColorModeContextType>({
    toggleColorMode: () => { },
});

// Custom hook to toggle between light and dark mode
export const useMode = (): [Theme, ColorModeContextType] => {
    const [mode, setMode] = useState<"light" | "dark">("dark");

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === "light" ? "dark" : "light")),
        }),
        []
    );

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return [theme, colorMode];
};
