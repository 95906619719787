import React, { useEffect } from 'react';
//import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { appConfig } from '../../utils/app.config';
import Loading from '../commons/loading/loading';

const LoginProcessing = () => {
  // const history = useHistory();

  useEffect(() => {
    // Fetch the authorization code from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');


    if (!code) {
      alert('Authorization code not found. Please try logging in again.');
      //  history.push('/login'); // Redirect to login if code is not found
    } else {
      // Send AJAX request to fetch the access token using axios
      axios
        .post(`${appConfig.baseUrl}/auth/oauth2/v2.0/accesstoken`, { code, state })
        .then((response) => {
          // Save access token to session storage
          sessionStorage.setItem('cva.jwt.access.token', response.data.access_token);

          if (state) {
            try {
              const stateObj = JSON.parse(atob(state));
              console.log(stateObj); 
              window.location.href = stateObj.location;
            } catch (error) {
              console.error("Error decoding state parameter", error);
            }
          }
          // Redirect to index.html after successful login
          // history.push('/index');
        })
        .catch(() => {
          alert('Login failed. Please try again.');
          //history.push('/login'); // Redirect to login page on error
        });
    }
  }, []);

  return (
    <div className="loading-container">
      <Loading />
    </div>
  );
};

export default LoginProcessing;
