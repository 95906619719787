
import React, { useEffect, useState } from 'react';
import { Link, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import Main from './pages/main';
import { getDesktopAppsUrl, getNotificationsUrl, getRecentSearchUrl, getTicketsCvaURL, getTrendingSearchUrl } from './utils/constants';
import { useDispatch } from 'react-redux';
import DesktopNotifications from './components/Notifications/DesktopNotifications/DesktopNotifications';
import Settings from './components/Settings/Settings';
import SearchApps from './components/AppsList/SearchApps/SearchApps';
import TicketStatus from './components/TicketStatus/TicketStatus';
import { useSelector } from 'react-redux';
import RaiseTicket from './components/RaiseTicket/RaiseTicket';
// import { updateTicketsCva } from '../../utils/redux-store/slices/ticketsCvaSlice';
// import UpdateHeaders from '../../application/commons/update-headers/update.headers';
// import ResetPassword from '../../application/commons/reset-password/reset.password';
// import Loading from '../../application/commons/loading/loading';
// import UserProfile from '../../application/commons/UserProfile/UserProfile';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../theme';
import { HeaderButton } from '../../../application/commons/headers/headers';
import { updateRecentSearches, updateTrendingSearches } from '../../../utils/redux-store/slices/searchSlice';
import { updateNotifications } from '../../../utils/redux-store/slices/desktopNotificationSlice';
import { updateDesktopApps } from '../../../utils/redux-store/slices/desktopAppsSlice';
import { updateTicketsCva } from '../../../utils/redux-store/slices/ticketsCvaSlice';
import Loading from '../../../application/commons/loading/loading';
import PrivacyPolicy from '../../../application/commons/privacy-policy/privacy-policy';
import UserProfile from '../../../application/commons/UserProfile/UserProfile';
import ResetPassword from '../../../application/commons/reset-password/reset.password';
import UpdateHeaders from '../../../application/commons/update-headers/update.headers';
import { DropdownLinks } from '../../../application/commons/drop-down/drop-down.menu';
import LiveAgent from '../../desktop-app/components/LiveAgent/LiveAgent';
import KbList from './components/KbList/KbList';
import KbArticle from './components/KbArticle/KbArticle';

const DesktopRoutes: React.FC<DesktopLayoutProps> = ({ redirectUrl }) => {
    const [loading, setLoading] = useState(true);
    const theme = useTheme(); // Access the current theme
    const colors = tokens(theme.palette.mode);
    console.log('color', colors.headers.text)
    const navigate = useNavigate();


    const notifications = useSelector((store: any) => store.notifications.notifications);
    const textColor = `text-${colors.headers.text}`;
    const dynamicButtons: HeaderButton[] = [
        { id: "home" }, { id: "ticket_status", },
        {
            id: "notifications",
            onClick: () => navigate(`${redirectUrl}/desktop/notifications`),
            content: <>
                <Link className={`nav-link `} to={`${redirectUrl}/desktop/notifications`}><i className={`bi-bell`}></i>
                    <div className="tooltip-text">Notifications</div>
                    <span className="position-absolute top-2 start-100 translate-middle badge rounded-pill bg-danger cva-badge">
                        {notifications.length}
                    </span>
                </Link>
            </>,
            position: 0
        },
        { id: "support", position: 1 }
    ];

    const profileItems: DropdownLinks[] = [
        {
            id: "settings"
        }
    ]

    useEffect(() => {
        getRecentSearches();
        getTrendingSearches();
        getNotifications();
        getDesktopApps();
        getTicketsCva();
    }, [])

    const dispatch = useDispatch();

    const getRecentSearches = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const result = await (await fetch(getRecentSearchUrl, options)).json();
            if (result) {
                dispatch(updateRecentSearches({ value: result }));
            }
        } catch (err) {
            console.log('ERROR :::', err);
            dispatch(updateRecentSearches({ value: [] }));
        }
    }

    const getTrendingSearches = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const result = await (await fetch(getTrendingSearchUrl, options)).json();
            if (result) {
                dispatch(updateTrendingSearches({ value: result }));
            }
        } catch (err) {
            console.log('ERROR :::', err);
            dispatch(updateTrendingSearches({ value: [] }));
        }
    }

    const getNotifications = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const result = await (await fetch(getNotificationsUrl, options)).json();
            if (result) {
                dispatch(updateNotifications({ value: result }));
            }
        } catch (err) {
            console.log('ERROR :::', err);
            dispatch(updateNotifications({ value: [] }));
        }
    }

    const getDesktopApps = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const result = await (await fetch(getDesktopAppsUrl, options)).json();
            if (result) {
                dispatch(updateDesktopApps({ value: result }));
            }
        } catch (err) {
            console.log('ERROR :::', err);
            dispatch(updateDesktopApps({ value: [] }));
        }
        setTimeout(() => setLoading(false), 2000);
    }

    const getTicketsCva = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const result = await (await fetch(getTicketsCvaURL, options)).json();
            if (result) {
                dispatch(updateTicketsCva({ value: result }));
            }
        } catch (err) {
            console.log('ERROR :::', err);
            dispatch(updateTicketsCva({ value: [] }));
        }
    }

    if (loading) {
        return <Loading />
    }


    return (
        <div>
            <Routes>
                <Route path="/" element={<><DesktopLayout redirectUrl={redirectUrl + '/desktop'} /><UpdateHeaders redirectUrl={redirectUrl} headersDynamicButtons={dynamicButtons} userProfileItems={profileItems} defaultUserProfileItemsOverride={[]} /></>} >
                    <Route index element={<DesktopHome redirectUrl={redirectUrl} />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/notifications' element={<DesktopNotifications />} />
                    <Route path='/user-profile' element={<UserProfile />} />
                    <Route path='/settings' element={<Settings />} />
                    <Route path='/apps' element={<SearchApps />} />
                    <Route path='/raise-ticket' element={<RaiseTicket />} />
                    <Route path='/ticket-status' element={<TicketStatus />} />
                    <Route path='/reset-password' element={<ResetPassword />} />
                    <Route path='/live-Agent' element={<LiveAgent />} />
                    <Route path='/kb-list' element={<KbList />} />
                    <Route path='/kb-article' element={<KbArticle />} />
                </Route>
            </Routes>
        </div>
    );
};

// Home component for /user
const DesktopHome: React.FC<DesktopLayoutProps> = ({ redirectUrl }) => {

    return (
        <>
            <Main redirectUrl={redirectUrl} />
        </>
    );
};

interface DesktopLayoutProps {
    redirectUrl: string;
}

export const DesktopLayout: React.FC<DesktopLayoutProps> = ({ redirectUrl }) => {

    return (
        <div className='main-page'>
            {/* <Headers /> */}
            {/* <main style={{ minHeight: '73vh' }}> */}
            <Outlet />
            {/* </main> */}
            {/* <Footer /> */}
        </div>
    );
};

export default DesktopRoutes;
