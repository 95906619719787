import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define the initial state structure
interface SearchState {
    recentSearches: string[],
    trendingSearches: string[]
}

const initialState: SearchState = {
    recentSearches: [],
    trendingSearches: []
};

// Create the login slice
const searchSlice = createSlice({
    name: "searches",
    initialState,
    reducers: {
        updateRecentSearches: (state: SearchState, action: PayloadAction<{ value: string[] }>) => {
            state.recentSearches = action.payload.value;
        },
        updateTrendingSearches: (state: SearchState, action: PayloadAction<{ value: string[] }>) => {
            state.trendingSearches = action.payload.value;
        },
    },
});

// Export actions and reducer
export const { updateRecentSearches, updateTrendingSearches } = searchSlice.actions;
export default searchSlice.reducer;