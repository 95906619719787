import React, { useEffect, useState } from 'react'
import "./engineer-assist.css"
import { Accordion } from 'react-bootstrap';
import Loading from '../../../application/commons/loading/loading';
import { appConfig } from '../../../utils/app.config';

interface SummaryProps {
    incidentNumber: string;
    summary: string;
    impact: string;
    rootCause: string
    date: string
}


interface LogEntry {
    _id?: string;
    name?: string;
    label?: string;
    output?: string;
    parameters?: string[];
    action_result?: string;
    action_name?: string;
    active?: boolean;
    INC?: string;
    flag?: boolean;
}

interface ActionProps {
    name?: string;
    label?: string;
    output?: string;
    parameters?: string[];
    action_result?: string;
    active?: boolean;
    INC?: string;
    flag?: boolean;
    _id?: string;
}

const EngineerAssist: React.FC = () => {

    const [searchInput, setSearchInput] = useState('');
    const [actions, setActions] = useState<ActionProps[]>([])
    const [summary, setSummary] = useState<SummaryProps | null>(null)
    const [recommendation, setRecommendation] = useState('')
    const [status, setStatus] = useState('')
    const [logs, setLogs] = useState<LogEntry[]>([])
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedAction, setSelectedAction] = useState<ActionProps | null>(null);
    const [searchClicked, setSearchClicked] = useState(false);
    const [isSearchButtonEnabled, setIsSearchButtonEnabled] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedAction(null);
    };

    useEffect(() => {
        // Validate the input against the regex pattern
        if (searchInput.length > 0) {
            if (regexPattern.test(searchInput)) {
                setIsSearchButtonEnabled(true);
                setValidationMessage('');
            } else {
                setIsSearchButtonEnabled(false);
                setValidationMessage('Invalid incident number format. Please use INC followed by 7 digits.');
            }
        } else {
            // Reset when input is empty
            setIsSearchButtonEnabled(false);
            setValidationMessage('');
            setSearchClicked(false)
        }
    }, [searchInput]);

    const handleSearchSubmit = () => {
        // e.preventDefault();
        console.log('[SSSS]', searchInput)
        if (searchInput && regexPattern.test(searchInput)) {
            setSearchClicked(true)
            fetchTicketData();
        }
    };
    const regexPattern = /^INC\d{7}$/;

    const fetchTicketData = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const result = await (await fetch(`${appConfig.baseUrl}/api/v2.0/metrics/tkt/status?id=${searchInput}`, options)).json();
            if (result) {
                setStatus(result.result.state)
                const summary: SummaryProps = {
                    incidentNumber: result.result.number,
                    summary: result.result.summary,
                    impact: result.result.impactData,
                    rootCause: result.result.rootCause,
                    date: result.result.opened_at
                }
                setSummary(summary)
                setTimeout(() => {
                    setRecommendation(result.result.recommendation)
                }, 3000)
                setActions(result.resultAction)
                fetchTicketLogs();
            }
        } catch (err) {
            console.log('ERROR :::', err);
        }
    }

    const fetchTicketLogs = async () => {
        try {
            const options = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const result = await (await fetch(`${appConfig.baseUrl}/api/v2.0/metrics/action/logs?id=${searchInput}`, options)).json();
            if (result) {
                setLogs(result.result)
            }
        } catch (err) {
            console.log('ERROR :::', err);
        }
    }

    const logActionClicked = async (action: ActionProps) => {
        try {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "incident": action.INC,
                    "short_description": "Short Description",
                    "action_name": action.name,
                    "action_parameter": action.parameters,
                    "label": action.label
                })
            };
            const result = await (await fetch(`${appConfig.baseUrl}/api/v2.0/metrics/action/taken`, options)).json();
            if (result) {
                fetchTicketLogs()
            }
        } catch (err) {
            console.log('ERROR :::', err);
        }
    }

    const handleActionClicked = (action: ActionProps) => {
        console.log('[ACTION CLICKED]', action)
        logActionClicked(action)
        setSelectedAction(action);
        setModalOpen(true);
    }

    return (
        <div className='engineer_assist_main_container'>
            <div className='engineer_assist_title'>
                <h1>Engineer Assist AI</h1>
            </div>
            <div className='engineer_assist_search_bar_main_container'>
                <div className='row engineer_assist_search_bar'>
                    <div className="col-xxl-8 col-xl-8 col-l-8 col-md-8 col-sm-8 col-8 ">
                        {/* <form onSubmit={handleSearchSubmit}> */}
                        <div className="engineer_assist_search_bar_container">
                            <img className='engineer_assist_search_bar_icon' src="/ui/static/img/desktop/Icon_feather_search.png" alt="search" />
                            <input
                                type="text"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                placeholder="Type incident number to search"
                            />
                        </div>
                        {/* </form> */}
                        {/* {validationMessage && (
                            <p style={{ color: 'red', marginTop: '8px' }}>{validationMessage}</p>
                        )} */}
                    </div>
                    {
                        searchClicked ?
                            <div className='col-xxl-4 col-xl-4 col-l-4 col-md-4 col-sm-4 col-4 engineer_assist_search_button' onClick={() => setSearchInput('')}> Clear</div>
                            :
                            <div className={`col-xxl-4 col-xl-4 col-l-4 col-md-4 col-sm-4 col-4 engineer_assist_search_button ${!isSearchButtonEnabled && 'engineer_assist_search_button_disabled'}`} onClick={handleSearchSubmit} >Search</div>
                    }
                </div>
                <div>
                    {validationMessage && (
                        <p style={{ color: 'red', marginTop: '8px' }}>{validationMessage}</p>
                    )}
                </div>
            </div>
            {
                searchClicked &&
                <div className='row engineer_assist_sub_container'>
                    <div className='col-xxl-4 col-xl-4 col-l-4 col-md-4 engineer_assist_sub_container_left'>
                        <div className='engineer_assist_actions'>
                            <div className='engineer_assist_sub_container_labels'>
                                <h4>Actions</h4>
                            </div>
                            <div className='engineer_assist_sub_container_left_labels_data'>
                                {
                                    actions?.length > 0 ?
                                        actions?.map((action: ActionProps, index) =>
                                            <div key={action._id || index} className='engineer_assist_action_button' onClick={() => handleActionClicked(action)}>{action.label}</div>
                                        )
                                        : <Loading height='80%'></Loading>
                                }
                            </div>
                        </div>
                        <div className='engineer_assist_Status'>
                            <div className='engineer_assist_sub_container_labels'>
                                <h4>Status</h4>
                            </div>
                            <div className='engineer_assist_sub_container_left_labels_data'>
                                <p style={{margin: 0}}>{status ? status : <Loading height='80%' />}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-8 col-xl-8 col-l-8 col-md-8 engineer_assist_sub_container_right'>
                        <div className='engineer_assist_summary'>
                            <div className='engineer_assist_sub_container_labels'>
                                <h4>Summary</h4>
                            </div>
                            <div className='engineer_assist_sub_container_right_labels_data'>
                                {
                                    summary ? <>
                                        <p>Number : {summary.incidentNumber}</p>
                                        <p>Date :{summary.date}</p>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Summary</Accordion.Header>
                                                <Accordion.Body>
                                                    {summary.summary}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Impact</Accordion.Header>
                                                <Accordion.Body>
                                                    {summary.impact}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Probable Root Cause</Accordion.Header>
                                                <Accordion.Body>
                                                    {summary.rootCause}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </>
                                        : <Loading height='80%' />
                                }
                            </div>
                        </div>
                        <div className='engineer_assist_recommendations'>
                            <div className='engineer_assist_sub_container_labels'>
                                <h4>Recommendation</h4>
                            </div>
                            <div className='engineer_assist_sub_container_right_labels_data'>
                                {
                                    recommendation ?
                                        <div dangerouslySetInnerHTML={{ __html: recommendation }}></div>
                                        : <Loading height='80%' />
                                }
                            </div>
                        </div>
                        <div className='engineer_assist_logs'>
                            <div className='engineer_assist_sub_container_labels'>
                                <h4>Logs</h4>
                            </div>
                            <div className='engineer_assist_sub_container_right_labels_data'>
                                {
                                    logs.length === 0 ?
                                        <Loading height='80%' />
                                        :
                                        logs.map((item: LogEntry) => {
                                            const greenRed = item.flag; // Assuming flag can be true, false, or null
                                            console.log("GREEN RED FLAG 2", greenRed);
                                            return (
                                                <div key={item._id}>
                                                    <strong>{item.action_name}</strong> - {item.action_result}
                                                    {greenRed === true && (
                                                        <span style={{ color: 'green', fontSize: '27px', fontWeight: 600, padding: '10px' }}>
                                                            &#10003; {/* Check mark */}
                                                        </span>
                                                    )}
                                                    {greenRed === false && (
                                                        <span style={{ color: 'red', fontSize: '27px', fontWeight: 600, padding: '10px' }}>
                                                            &#9888; {/* Warning sign */}
                                                        </span>
                                                    )}
                                                    {greenRed === null && ( // Add condition for null or undefined flag
                                                        <span style={{ fontSize: '27px', fontWeight: 600, padding: '10px' }}>
                                                            {/* No additional indicator */}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                selectedAction &&
                <ConfirmationModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    action={selectedAction}
                />
            }
        </div >
    )
}


interface ConfirmationModalProps {
    isOpen: boolean;
    onClose: () => void;
    action: ActionProps;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, action }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>{action.label}</h2>
                    <button className="close-button" onClick={onClose}>✖</button>
                </div>
                <div className="modal-body">
                    <strong>{action.name}</strong> - {action.action_result}
                    {action.flag === true && (
                        <span style={{ color: 'green', fontSize: '27px', fontWeight: 600, padding: '10px' }}>
                            &#10003; {/* Check mark */}
                        </span>
                    )}
                    {action.flag === false && (
                        <span style={{ color: 'red', fontSize: '27px', fontWeight: 600, padding: '10px' }}>
                            &#9888; {/* Warning sign */}
                        </span>
                    )}
                    {action.flag === null && ( // Add condition for null or undefined flag
                        <span style={{ fontSize: '27px', fontWeight: 600, padding: '10px' }}>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EngineerAssist