import React from 'react';
import { Outlet } from 'react-router-dom';
import Headers, { HeaderButton } from '../commons/headers/headers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Footer from '../commons/footer/footer';


export const ApplicationLayout: React.FC = () => {

    return (
        <div>
            <Headers />
            <main className="main-content">
                <Outlet />
            </main>
            <Footer />
        </div>
    );
};

