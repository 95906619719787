import React, { useEffect, useState } from "react";
import "./TicketStatus.css";
import ErrorPopup from "../Popups/ErrorPopup/ErrorPopup";
import { Badge, Button, Card, Typography, Chip, Divider } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import TicketDetails from "../Popups/TicketDetails/TicketDetails";
import { Link } from "react-router-dom";
import Loading from "../../../../../application/commons/loading/loading";
import BackButton from "../../../../../application/commons/back-button/back-button";
import CustomTabs from "../CustomTabs/CustomTabs";
import { useTheme } from '@mui/material';
import { tokens } from "../../../../../theme";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  console.log();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

const TicketCard: React.FC<{ ticket: any }> = ({ ticket }) => {
  console.log("hurry", ticket);
  const [modalOpen, setModalOpen] = useState(false);
  const handlePopUpChange = () => {
    setModalOpen(!modalOpen);
  };

  const theme = useTheme(); // Access the current theme
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    // Set CSS variables based on the current theme colors
    document.documentElement.style.setProperty('--header-bg-color', colors.headers.backround);
    document.documentElement.style.setProperty('--header-text-color', colors.headers.text);
    document.documentElement.style.setProperty('--box-shadow', colors.headers.boxShadow);

}, [theme]);

  return (
    <>
      {ticket?.length === 0 ? (
        <>
          <p>No Tickets</p>
        </>
      ) : (
        <div
        //   variant="outlined"
        //   sx={{
        //     borderRadius: 4,
        //   }}
       
          className="card listCard shadow-sm"
        >
          <div className="card_header">
            <div
              style={{ padding: "8px 16px",  backgroundColor:
              ticket.state === "New" ? colors.primary[500]: colors.grey[500],
              color: 'white' }}
           
              className='badge rounded-pill'
            >{ticket.state}</div>
            <p style={{margin: 0, fontSize: '12px'}}>
              Pending with:{" "}
              {ticket.assignment_group
                ? ticket.assignment_group
                : "No Assignment Group"}
            </p>
          </div>
          <hr style={{ margin: "16px 0px" }} />
          <div className="flex req_background">
            <div>
              <div>
                <p style={{margin: 0, color: 'var(--header-text-color)'}}>
                  {ticket.number}: {ticket.short_description}
                </p>
              </div>
            </div>
            {ticket.state === "New" ? (
              <>
                <div>
                  <button
                    onClick={handlePopUpChange}
                    className="btn btn-primary"
                    style={{ gap: "8px" }}
                    // color="primary"
                    // style={{ gap: "8px", borderRadius: '50px',     backgroundColor: "#1976d2" }}
                    // variant="contained"
                    // size="medium"
                  >
                    <div className="update_btn"></div>
                    Update
                  </button>
                  {modalOpen && (
                    <TicketDetails
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      ticketDetails={ticket}
                    />
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



const TicketStatus: React.FC = () => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [accountExist, setAccountExist] = useState<boolean>(true);
  const error_msg =
    "Your servicenow account is not available. Please contact admin to create one";
  const [searchTerm, setSearchTerm] = useState("");

  const ticketsCva: any = useSelector((store: any) => store.tickets.ticketsCva);
  const activeCva: any = ticketsCva?.activeTickets;
  const closedCva: any = ticketsCva?.resolvedOrClosed;
  const totalTicketsCva = [...activeCva, ...closedCva];
  console.log(ticketsCva);
  const [filteredData, setFilteredData] = useState<any>(totalTicketsCva);

  console.log("tickets Cva", activeCva);
  console.log("tickets Cva", closedCva);

 

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    // Cleanup the timeout on component unmount
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const remove = () => {
    setSearchTerm("");
    setFilteredData(totalTicketsCva);
  };

  const handleSearch = (e: any) => {
    const valueable = e.target.value;
    setSearchTerm(valueable);
    console.log(valueable);
    const filteredTickets = totalTicketsCva?.filter((currentVal) =>
      currentVal?.number?.toLowerCase()?.includes(valueable?.toLowerCase())
    );
    setFilteredData(filteredTickets);
    console.log("filetered value", filteredData);
  };

  //   const filteredTickets = tickets.filter(ticket => {
  //     ticket.id.toLocaleLowerCase().includes(searchTerm.toLowerCase())
  // })

  const tabData = [
    {
      name: "All",
      content: (
        <>
          <div
            style={{
              margin: "12px 10px",
              display: "flex",
            }}
          >
            <section style={{ width: "100%" }} className="chat-input-container">
              <form className="form_length" action="#" method="post">
                <div className="iconSearch"></div>
  
                <input
                  type="text"
                  maxLength={20}
                  className="textarea"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search Ticket Number"
                  aria-label="Number"
                />
                <div>
                  <div className="closeicn" onClick={remove}></div>
                </div>
              </form>
            </section>
          </div>
          <div className="list_tickets">
            {filteredData?.length > 0 ? (
              filteredData?.map((ticket: any) => (
                <>
                  <TicketCard key={ticket?.number} ticket={ticket} />
                </>
              ))
            ) : (
              <>
                <div> No Search Result Found </div>
              </>
            )}
          </div>
        </>
      ),
    },
    {
      name: "Open",
      content: (
        <div style={{ height: "calc(100vh - 170px)" }}>
          {activeCva?.length === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "12px",
                }}
              >
                No Open Ticket
              </div>
            </>
          ) : (
            activeCva?.map((ticket: any) => (
              <TicketCard ticket={ticket} key={ticket?.number} />
            ))
          )}
        </div>
      ),
      badge: activeCva?.length === 0 ? 0 : activeCva?.length

    },
    {
      name: "Closed",
      content: (
        <div style={{ height: "calc(100vh - 170px)" }}>
          {closedCva?.length === 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "12px",
                }}
              >
                No Closed Ticket
              </div>
            </>
          ) : (
            closedCva.map((ticket: any) => (
              <TicketCard key={ticket?.number} ticket={ticket} />
            ))
          )}
        </div>
      ),
      badge: closedCva?.length === 0 ? '0' : closedCva?.length
    },
  ];

  return (
    <>
      {accountExist ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className="flex" style={{ alignItems: "center" }}>
            <div id="back" className="back">
              <BackButton />
            </div>
            <div className="status">
              <p style={{ cursor: "pointer", margin: "0px" }}>Ticket Status</p>
            </div>
            <div className="rectangle">
              <Link
                to="/mn/desktop/raise-ticket"
                style={{ textDecoration: "none" }}
              >
                <button
                  // variant="contained"
                  className="btn btn-primary"
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <i className="bi bi-ticket tiktIcn"></i>
                  <div className="raiseTicket">Raise Ticket</div>
                </button>
              </Link>
            </div>
          </div>
          <div style={{ borderRadius: "24px" }} className="main_div">
            <div className="tabs" id="tabs">
              <CustomTabs data={tabData} />

              {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="fullWidth"
                                    aria-label="basic tabs example"
                                    selectionFollowsFocus
                                >
                                    <Tab label="All" {...a11yProps(0)} />
                                    <Tab
                                        label={
                                            <Badge
                                                badgeContent={activeCva?.length === 0 ? 0 : activeCva?.length}
                                                color="primary"
                                                sx={{
                                                    "& .MuiBadge-badge": {
                                                        right: "-16px",
                                                        top: "9px",
                                                        padding: "0 4px",
                                                    },
                                                }}
                                            >
                                                Open
                                            </Badge>
                                        }
                                        style={{color: 'rgba(0, 0, 0, 0.6)'}}
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        label={
                                            <Badge
                                                badgeContent={closedCva?.length === 0 ? '0' : closedCva?.length}
                                                sx={{
                                                    "& .MuiBadge-badge": {
                                                        right: "-16px",
                                                        top: "9px",
                                                        padding: "0 4px",
                                                        background: "grey",
                                                        color: "white",
                                                    },
                                                }}
                                            >
                                                Closed
                                            </Badge>
                                        }
                                        style={{color: 'rgba(0, 0, 0, 0.6)'}}

                                        {...a11yProps(2)}
                                    />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <div
                                    style={{
                                        margin: "12px 22px",
                                        display: "flex",
                                    }}
                                >
                                    <section
                                        style={{ width: "100%" }}
                                        className="chat-input-container"
                                    >
                                        <form className="form_length" action="#" method="post">
                                            <div className="iconSearch"></div>

                                            <input
                                                type="text"
                                                maxLength={20}
                                                className="textarea"
                                                value={searchTerm}
                                                onChange={handleSearch}
                                                placeholder="Search Ticket Number"
                                                aria-label="Number"
                                            />
                                            <div>
                                            <div className="closeicn" onClick={remove}></div>

                                            </div>
                                        </form>
                                    </section>
                                </div>
                                <div className="list_tickets">

                                    {filteredData?.length > 0 ? filteredData?.map((ticket: any) => (
                                        <>
                                            <TicketCard key={ticket?.number} ticket={ticket} />
                                        </>
                                    )) : <>
                                        <div> No Search Result Found </div>
                                    </>
                                    }
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <div  style={{height: 'calc(100vh - 170px)'}}>
                                {activeCva?.length === 0 ? <>
                                    <div style={{display: 'flex', justifyContent: 'center', padding: '12px'}}>No Open Ticket</div>
                                </> : activeCva?.map((ticket: any) => (
                                    <TicketCard
                                        ticket={ticket}
                                        key={ticket?.number}
                                    />
                                ))}
                                </div>
                                
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <div style={{height: 'calc(100vh - 170px)'}}>
                                {closedCva?.length === 0 ? <>
                                    <div style={{display: 'flex', justifyContent: 'center', padding: '12px'}}>No Closed Ticket</div>
                                </> : closedCva.map((ticket: any) => (
                                    <TicketCard key={ticket?.number} ticket={ticket} />
                                ))}
                                </div>
                               
                            </CustomTabPanel> */}
            </div>
          </div>
        </div>
      ) : (
        <ErrorPopup error_msg={error_msg} />
      )}
    </>
  );
};

export default TicketStatus;
