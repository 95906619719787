import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '../basic.layout';
import Error401 from '../../pages/error401';
import Error500 from '../../pages/error500';
import LoginSelection from '../../pages/loginSelection';
import LoginProcessing from '../../pages/login.process';
import ViewSelection from '../../pages/view.selection';

const BasicRoutes: React.FC = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<BasicLayout />}>
                    <Route index element={<Navigate to="/mn" />} />
                    <Route path="/error401" element={<Error401 />} />   {/* Route for 401 */}
                    <Route path="/error500" element={<Error500 />} />   {/* Route for 500 */}
                    <Route path="/loginSelection" element={<LoginSelection />} />
                    <Route path="/loginProcess" element={<LoginProcessing />} />
                    <Route path="/view" element={<ViewSelection />} />

                    
                </Route>
            </Routes>
        </div>
    );
};



export default BasicRoutes;
