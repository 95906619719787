import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './profile.css';
import { appConfig } from '../../../utils/app.config';
import { getUserProfileUrl } from '../../../utils/constants/constants';
import { updateLoginDetails } from '../../../utils/redux-store/slices/loginSlice';
import { useDispatch } from 'react-redux';
import { isEmptyObject } from '../../../utils/helpers';
import DropDownMenu, { DropdownLinks } from '../drop-down/drop-down.menu';

interface ProfileProps {
    redirectUrl?: string;
    smallScreen?: boolean;
    userItems?: Array<DropdownLinks>; // Array of user-provided items
    defaultItemsOverride?: Array<Partial<DropdownLinks>>; // Allows partial customization of default items
}


const Profile: React.FC<ProfileProps> = ({ redirectUrl, userItems = [], smallScreen, defaultItemsOverride }) => {
    redirectUrl = redirectUrl ? redirectUrl : "/";
    const dispatch = useDispatch();

    console.log("useritems", userItems)

    const userProfile = useSelector((store: any) => store.login.loginDetails);

    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const token = sessionStorage.getItem(appConfig.loginTokenName);
                console.log(token)
                const options = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`
                    }
                };
                const result = await (await fetch(getUserProfileUrl, options)).json();
                if (result) {
                    dispatch(updateLoginDetails({ value: result.payload }));
                }
            } catch (err) {
                console.log('ERROR :::', err);
                dispatch(updateLoginDetails({ value: {} }));
            }
        }
        getUserProfile();
    }, [])


    if (isEmptyObject(userProfile)) {
        return null
    }
    
    let itemItems: any[] = [
        {
            id: "profile"
        },
        {
            id: "about",
        },
        {
            id: "policy",
            icon: 'lock',
            text: 'Privacy Policy New',
            link: `${redirectUrl}/privacy-policy`,
            position: 5
        },
        {
            id: "notification",
            icon: 'bell',
            text: 'Notification',
            link: '#',
            disabled: true,
            position: 6
        },
        {
            id: 'resetPassword'
        },
        {
            id: 'logout'
        }
    ];

    const newItems: any[] = [
        {
            id: 'theme'
        },
        {
            id: 'ticket-status'
        }
    ]
    

    if(smallScreen){
      
        itemItems = [...itemItems, ...newItems]
    }
    console.log('small screen ', itemItems)


    return (
        <>
            <a className="nav-link dropdown-toggle cva-user-profile-icon" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className={`bi-circle`}><span> {userProfile['displayName'][0] + userProfile['family_name'][0]}</span></i>
                <div className="tooltip-text">Profile</div>
            </a>
            <DropDownMenu redirectUrl={redirectUrl} linkItems={[...itemItems, ...userItems]} />
        </>);
};

export default Profile;
