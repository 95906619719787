import React, { useEffect } from 'react';
import './footer.css';
import { appConfig } from '../../../utils/app.config';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';


const Footer: React.FC = () => {
    const theme = useTheme(); // Access the current theme
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        // Set CSS variables based on the current theme colors
        document.documentElement.style.setProperty('--header-bg-color', colors.headers.backround);
        document.documentElement.style.setProperty('--header-text-color', colors.headers.text);
        document.documentElement.style.setProperty('--box-shadow', colors.headers.boxShadow);

    }, [theme]);

    return (
        <div className="main-footer">
            {/* <div className="tata_logo"> */}
            <img className={theme.palette.mode === 'dark' ? 'tata_logo white_tata_logo' : 'tata_logo' } src={appConfig.imagesUrl + '/logos/tata.png'} />
            {/* </div> */}
            <div>
                <div className="footer_text">Copyright © 2024. Tata Consultancy Services.</div>
                <div className="footer_text">Powered by Cognix™</div>
            </div>
        </div>
    )
};

export default Footer;
