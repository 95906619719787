import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// Define the initial state structure
interface NotificationState {
    notifications: string[],
}

const initialState: NotificationState = {
    notifications: []
};

// Create the login slice
const desktopNotificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        updateNotifications: (state: NotificationState, action: PayloadAction<{ value: string[] }>) => {
            state.notifications = action.payload.value;
        },
    },
});

// Export actions and reducer
export const { updateNotifications } = desktopNotificationSlice.actions;
export default desktopNotificationSlice.reducer;