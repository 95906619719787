import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { ViewUser } from './components/view.user.comp';
import { AddUser } from './components/add.user.comp';
import { ModuleAccessUser } from './components/module.access.user.comp';

const UserRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<UserLayout />}>
                <Route index element={<ViewUser />} />
                <Route path="add" element={<AddUser />} />  
                <Route path="view" element={<ViewUser />} />
                <Route path="module-access" element={<ModuleAccessUser />} />

            </Route>
        </Routes>
    );
};

const UserLayout: React.FC = () => {
    return (
        <div style={{padding: '16px'}}>
            <h1>Lay Out User</h1>
            <Outlet />  {/* Outlet renders the child routes like /user/add */}
        </div>
    );
};

export default UserRoutes;
