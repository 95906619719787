import React, { useContext, useEffect } from 'react';
import './headers.css';
import { Link, useNavigate } from 'react-router-dom';
import Profile from '../profile/profile';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useTheme } from '@mui/material/styles'; // Import useTheme from MUI
import { ColorModeContext, tokens } from '../../../theme';
import DropDownMenu, { DropdownLinks } from '../drop-down/drop-down.menu';
import { useMediaQuery } from '@mui/material';


export interface HeaderButton {
    id?: string,
    content?: React.ReactNode;
    position?: number;
    onClick?: () => void; // Optional click handler
    showOnSmallScreen?: boolean;
}

interface HeaderProps {
    isOpen?: boolean;
}

const Headers: React.FC<HeaderProps> = ({isOpen}) => {
    const colorMode = useContext(ColorModeContext); // Access color mode context
    const theme = useTheme(); // Access the current theme
    const colors = tokens(theme.palette.mode);

    const redirectUrl: string = useSelector((state: any) => state.headers.redirectUrl);
    let inputItems: HeaderButton[] = useSelector((state: any) => state.headers.headersDynamicButtons);
    let profileInputItems: DropdownLinks[] = useSelector((state: any) => state.headers.userProfileItems);
    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width: 536px)');

    const profileExists = inputItems.some(item => item.id === "profile");
    if (!profileExists) {
        inputItems = [...inputItems, { id: "theme" }, { id: "profile" }];
    }

    useEffect(() => {
        // Set CSS variables based on the current theme colors
        document.documentElement.style.setProperty('--header-bg-color', colors.headers.backround);
        document.documentElement.style.setProperty('--header-text-color', colors.headers.text);
        document.documentElement.style.setProperty('--box-shadow', colors.headers.boxShadow);
        document.documentElement.style.setProperty('--cards-bg-color', colors.cards.backround);
        document.documentElement.style.setProperty('--cards-text-color', colors.cards.text);

    }, [theme]);

    const textColor = `text-${colors.headers.text}`;

   

    const defaultItems: HeaderButton[] = [
        {
            id: "home",
            onClick: () => navigate(`${redirectUrl}/desktop`),
            content:
                <Link className={`nav-link ${textColor}`} to={`${redirectUrl}/desktop`}>
                    <i className="bi-house"></i>
                    <div className="tooltip-text">Home</div>
                </Link>,
            position: 0,
            showOnSmallScreen: true
        },
        {
            id: "ticket_status",
            onClick: () => navigate(`${redirectUrl}/desktop/ticket-status`),
            content:
                <Link className={`nav-link ${textColor}`} to={`${redirectUrl}/desktop/ticket-status`}>
                    <i className="bi-ticket-perforated"></i>
                    <div className="tooltip-text">Ticket Status</div>
                </Link>,
            position: 0,
            showOnSmallScreen: false

        },
        {
            id: "support",
            content:
                <Link className={`nav-link ${textColor} dropdown-toggle`} to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi-headset"></i>
                    <div className="tooltip-text">Support</div>
                    <DropDownMenu redirectUrl={redirectUrl} linkItems={[{ id: "agent" }, { id: "chat" }, { id: "ticket" },]} />
                </Link>,
            position: 0,
            showOnSmallScreen: true

        },
        {
            id: "theme",
            content:
                <>
                    <i className={`bi ${theme.palette.mode === 'dark' ? 'bi-sun' : 'bi-moon'}`}></i>
                    <div className="tooltip-text">{theme.palette.mode === 'dark' ? "Dark Theme" : "Light Theme"}</div>
                </>,
            onClick: () => colorMode.toggleColorMode(),
            position: 9,
            showOnSmallScreen: false

        },
        {
            id: "profile",
            content: <Profile redirectUrl={redirectUrl} userItems={profileInputItems} smallScreen = {isSmallScreen} />,
            position: 10,
            showOnSmallScreen: true

        }
    ];


    
  

    const mergeItems = (defaultItems: HeaderButton[], inItems: HeaderButton[]) => {
        const linkItemIds = inItems.map(item => item.id);
        let mergedItems = defaultItems.filter(defaultItem => linkItemIds.includes(defaultItem.id));
        inItems.forEach(linkItem => {
            const index = mergedItems.findIndex(defaultItem => defaultItem.id === linkItem.id);
            if (index !== -1) {
                mergedItems[index] = { ...mergedItems[index], ...linkItem };
            } else {
                mergedItems.push(linkItem);
            }
        });

        return mergedItems;
    };

    const finalItems = mergeItems(defaultItems, inputItems).sort((a, b) => (a.position || 0) - (b.position || 0));

    return (
        <header className="main-header">
            <div className={`main-header-left-buttons ${isOpen ? 'open' : 'closed'}`}>
                {/* <Link to={redirectUrl || '/'}> */}
                {/* <img className='tcslogo' src="/ui/static/img/logos/Cognix_logo.png" /> */}
                <h3 style={{ color: colors.headers.text, margin: '0px' }}>Tcs cognix <sup>TM</sup> </h3>
                {/* </Link> */}
            </div>

            {/* {isSmallScreen ? () : (
            )} */}

            <div className="main-header-right-buttons">
                {finalItems?.map((button, index) => (
                    isSmallScreen? (button.showOnSmallScreen??button.showOnSmallScreen) &&
                     <div className={`main-header-icon-button ${textColor}`} key={index} onClick={button.onClick}>
                        {button.content}
                    </div>: <div className={`main-header-icon-button ${textColor}`} key={index} onClick={button.onClick}>
                        {button.content}
                    </div>
                    
                ))}
            </div>
        </header>
    );
};

export default Headers;
