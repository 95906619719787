import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './BotProfiles.css';
import '../../../styles/formStyles.css'; // Import global form styles
import { appConfig } from '../../../utils/app.config';
import MinimizableWebChat from '../../../application/commons/chat/MinimizableWebChat';
import { useTheme } from '@mui/material';

interface BotProfile {
    id: string;
    name: string;
}

const BotProfiles: React.FC = () => {
    const [botProfiles, setBotProfiles] = useState<BotProfile[]>([]);
    const [selectedBot, setSelectedBot] = useState<string | null>(null);

    useEffect(() => {
        // Fetch bot profiles from the REST API
        const fetchBotProfiles = async () => {
            try {
                const response = await axios.get(`${appConfig.baseUrl}/api/v2.0/litebot/activebots`);
                setBotProfiles(response.data);
            } catch (error) {
                console.error("Error fetching bot profiles:", error);
            }
        };

        fetchBotProfiles();
    }, []);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedBot(event.target.value);
    };

    
    useEffect(() => {

    }, [selectedBot]);

    let theme = useTheme();
    let color = theme.palette.mode;

    return (
        <div data-bs-theme={color} className={`bot-profiles-page ${color == 'dark'? 'dark-theme': 'light-theme'}`}>
            <div className="row">
                <div className="col-md-6">
                    <h2>Bot Profiles</h2>
                    <label htmlFor="bot-select">Choose a Bot Profile:</label>
                    <select id="bot-select" onChange={handleSelectChange}>
                        <option value="">-- Select a bot --</option>
                        {botProfiles.map((profile) => (
                            <option key={profile.id} value={profile.id}>
                                {profile.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {selectedBot && (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="bot-details">
                                <h3>Selected Bot Details</h3>
                                <p>Bot ID: {selectedBot}</p>
                                <p>Bot Name: {botProfiles.find(bot => bot.id === selectedBot)?.name}</p>
                            </div>
                            <div>
                                <MinimizableWebChat key={selectedBot} bot={selectedBot} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default BotProfiles;
