import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BasicLayout } from '../../application/layout/basic.layout';
import MinimizableWebChat from '../../application/commons/chat/MinimizableWebChat';
import { Card } from 'react-bootstrap';
import { useTheme } from '@mui/material';



// Define the routes for the user module
const ChatRoutes: React.FC = () => {
    return (
        <Routes>
            <Route >
                <Route index element={<ChatHome />} />  {/* Renders at /user */}
            </Route>
        </Routes>
    );
};

// Home component for /user
const ChatHome: React.FC = () => {
    let theme = useTheme();
    let color = theme.palette.mode;
    return (
        <div data-bs-theme={color} style={color === 'dark' ? {
            backgroundColor: "black",
            color: "white", height: "100%"
        } : {
            backgroundColor: "white",
            color: "black", height: "100%"
        }}>
            <div className="m-2">
                <Card className='mt-2' text={color === "dark" ? 'white' : 'dark'}>
                    <h2 className='text-center'>Chat Home</h2>
                    <div className='card-body'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eu gravida elit. Nullam sagittis vulputate odio, sed tempus est placerat et. Morbi porttitor ante quis sapien fermentum dapibus. Ut at iaculis justo. Phasellus nec velit volutpat felis elementum sodales at scelerisque velit. Vivamus egestas magna sed eros vulputate suscipit. Aenean vitae aliquet lacus. In tincidunt, augue nec hendrerit ullamcorper, tortor ligula posuere purus, a ornare ligula ex nec erat.
                        Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer faucibus dolor at nibh bibendum, ac pretium neque hendrerit. Phasellus tincidunt pellentesque diam. Nam gravida condimentum mauris, ut maximus mi mollis vitae. Aliquam consectetur ipsum at purus pulvinar eleifend. Suspendisse quis accumsan odio, posuere sollicitudin metus. Vivamus mattis justo nulla, vel dignissim elit sagittis nec. Nullam purus ex, facilisis id nulla eu, pulvinar viverra urna. Integer non volutpat diam. Fusce vulputate porttitor elit vitae lacinia. Praesent lectus massa, efficitur nec ipsum nec, egestas suscipit felis.
                        Pellentesque ut ligula vel ligula sollicitudin volutpat et in neque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aenean suscipit dictum erat vel lobortis. Suspendisse cursus tempus nulla at auctor. Ut maximus turpis ut eros consectetur rutrum. Maecenas quis nulla non ex ullamcorper rhoncus. Phasellus sed molestie tellus. Sed sollicitudin, tortor sit amet vestibulum faucibus, velit leo bibendum nisl, sed porttitor nisi orci elementum nunc. Donec tincidunt tempor est, in efficitur sem.
                    </div>
                </Card>

                <MinimizableWebChat bot="B0012" />
            </div>

        </div>
    );
};



export default ChatRoutes;
