import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/global.css';

const ViewSelection: React.FC = () => {

    return (
        <div className="container-fluid">
            <div className="row row-cols-1 row-cols-md-2 g-4">
                <div className="col">
                    <div className="card">
                        <div className="card-body">

                            <i className="bi-layout-sidebar" style={{ fontSize: '4rem', color: 'cornflowerblue' }}></i>
                            <h5 className="card-title">Menu View</h5>
                            <p className="card-text">App View , App Will Open With this Container</p>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-body">

                            <i className="bi-collection" style={{ fontSize: '4rem', color: 'cornflowerblue' }}></i>
                            <h5 className="card-title">App View</h5>
                            <p className="card-text">Default View , App Will Open in Window without side Menu.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewSelection;
