
import React from 'react';
import './RaiseTicket.css';
import Form from './Form/Form';
import BackButton from '../../../../../application/commons/back-button/back-button';
import { useLocation } from 'react-router-dom';



const RaiseTicket: React.FC = () => {

    const location = useLocation();
  const { ticketData,  type} = location.state || {};
    console.log("ticket data", ticketData, type)

    return (
        <>
            <div id="back" className="back">
                <BackButton />
            </div>
            <div className="raise_ticket" >
                <div className="raise_image">
                    <div>
                    <div className="bot_logo"></div>

                    </div>
                </div>
                
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                <div className="text1">
                    <p>Raise a {type ? type: 'Ticket' }</p>
                </div>
                <div className="text2">
                    <p>{ticketData? ticketData.short_description: '' }</p>
                </div>



                <div className="textbox1">
                    <Form formType={type}></Form>
                </div >

                </div>
                




            </div >
        </>
    );
};

export default RaiseTicket;

