// src/pages/Contract.tsx
import React, { useState } from 'react';
import { FaUpload, FaDownload } from 'react-icons/fa'; // Icons for upload and download buttons
import './Contract.css'; // Optional: Create this CSS file for additional styles.

const Contract: React.FC = () => {
    const [content, setContent] = useState('');
    const [summary, setSummary] = useState('');

    const handleSummarize = () => {
        // Add your summarize functionality here
        console.log("Summarizing content:", content);
        // Example: Just for demo purpose, returning the first 100 characters
        setSummary(content.substring(0, 100));
    };

    const handleReset = () => {
        setContent('');
        setSummary('');
    };

    const handleUpload = () => {
        // Add your upload functionality here
        console.log("Upload button clicked");
    };

    return (
        <div className="contract-container">
            <div className="header">
                <button className="btn btn-circle" onClick={handleUpload}>
                    <FaUpload />
                </button>
            </div>
            <div className="content-area">
                <div className="form-group">
                    <label htmlFor="uploadContent">Upload or Paste Your Content Here</label>
                    <textarea
                        id="uploadContent"
                        className="form-control"
                        rows={10}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="summaryOutput">Your Summary</label>
                    <textarea
                        id="summaryOutput"
                        className="form-control"
                        rows={10}
                        value={summary}
                        readOnly
                    />
                </div>
            </div>

            <div className="footer">
                <div className="word-count">
                    Word Count: {content.split(/\s+/).filter(Boolean).length}
                </div>
                <button className="btn btn-primary" onClick={handleSummarize}>Summarize Me</button>
                <button className="btn btn-circle" onClick={() => console.log("Export button clicked")}>
                    <FaDownload />
                </button>
            </div>
            <div className="button-group">
                <button className="btn btn-secondary" onClick={handleReset}>Reset</button>
            </div>
        </div>
    );
};

export default Contract;
