import React from 'react';
import { Outlet } from 'react-router-dom';

export const BasicLayout: React.FC = () => {
    return (
        <div>
            <main className="main-content">
                <Outlet />
            </main>
        </div>
    );
};
