import { Box, Button, TextField, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import BackButton from "../../../../application/commons/back-button/back-button";
import "./LiveAgent.css";
import { appConfig } from "../../../../utils/app.config";

const LiveAgent: React.FC = () => {
  const [isListening, setIsListening] = useState(false);
  const [textInput, setTextInput] = useState();

  // SpeechRecognition setup
  const SpeechRecognition =
    (window as any).SpeechRecognition ||
    (window as any).webkitSpeechRecognition;
  const recognition = new SpeechRecognition();

  recognition.continuous = false;
  recognition.interimResults = false;
  recognition.lang = "en-US";

  const startListening = () => {
    recognition.start();
    setIsListening(true);
  };

  const stopListening = () => {
    recognition.stop();
    setIsListening(false);
  };

  recognition.onresult = (event: any) => {
    const transcript = event.results[0][0].transcript;
    console.log(event);
    console.log(transcript);
    setTextInput(transcript);
    setIsListening(false);
  };

  recognition.onend = () => {
    setIsListening(false);
  };

  const sendMessage= () => {
      console.log("send message", textInput)
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          height: "100vh",
          paddingBottom: "24px",
        }}
      >
        <div
          className="flex"
          style={{
            alignItems: "center",
            paddingRight: "16px",
            flex: "1 0 auto",
          }}
        >
          <div id="back" className="back">
            <BackButton />
          </div>
          <div className="status">
            <p style={{ cursor: "pointer", margin: "0px" }}>Live Agent</p>
          </div>
          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#d32f2f",
                gap: "10px",
                borderRadius: "50px",
              }}
              color="error"
            >
              <div>End Chat</div>
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "0px 16px",
            height: "-webkit-fill-available",
          }}
        >
          <Box className="live-agent-wrapper"></Box>
          <div style={{ display: "flex", gap: "10px", alignItems: 'flex-end' }}>
            <TextField
              multiline
              maxRows={4}
              value={textInput}
              onChange={(e: any) => setTextInput(e.target.value)}
              placeholder="Enter Message"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-input": { boxShadow: "none" },
                "& .MuiOutlinedInput-root": {
                  padding: "12px 24px",
                  background: "white",
                  borderRadius: "16px",
                },
              }}
            ></TextField>
            <IconButton
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "47px",
                width: "47px",
                background: "white",
                border: "1px solid #c4c4c4",
              }}

              onClick={sendMessage}
            >
              <i
                style={{ transform: "rotate(45deg)" }}
                className="bi bi-send-fill"
              ></i>
            </IconButton>
            <IconButton
              type="button"
              className="voice-search-button"
              onClick={isListening ? stopListening : startListening}
              aria-label="Voice Search"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "47px",
                width: "47px",
                background: "#1976d2",
              }}
            >
              <img style={{height: '30px', width: '30px'}}
                src={appConfig.imagesUrl + "/desktop/microphone.png"}
                alt="Voice Search"
              />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveAgent;
