import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { appConfig } from '../../../utils/app.config';

const Login = ({ children }: any) => {
    //const navigate = useNavigate();
    const [checkLogin, setCheckLogin] = useState<boolean>(false);

    const encodedState = btoa(JSON.stringify({ location: window.location.href, bot: 'B001' }));
    const loginUrl = `${appConfig.loginUrl}&state=${encodedState}`

    useEffect(() => {
        // Function to check the token's validity
        const checkToken = () => {
            const token = sessionStorage.getItem(appConfig.loginTokenName);
            if (!token) {
                //navigate(appConfig.loginUrl);
                window.location.href = loginUrl;
            } else {
                try {
                    const decodedToken = jwtDecode(token) as any;
                    const currentTime = Date.now() / 1000; // Get current time in seconds

                    if (decodedToken.exp < currentTime) {
                        // Token expired, navigate to login
                        sessionStorage.removeItem(appConfig.loginTokenName);
                        window.location.href = loginUrl;
                    } else {
                        setCheckLogin(true)
                    }
                } catch (error) {
                    // If token is invalid, navigate to login
                    sessionStorage.removeItem(appConfig.loginTokenName);
                    window.location.href = loginUrl;
                }
            }
        };

        checkToken();
    }, [checkLogin]);

    if (checkLogin) {
        return <>{children}</>;
    }
    return null;
};

export default Login;
