import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/loginSlice";
import searchReducer from "./slices/searchSlice";
import notificationReducer from "./slices/desktopNotificationSlice";
import desktopAppsReducer from "./slices/desktopAppsSlice";
import ticketsCvaReducer from "./slices/ticketsCvaSlice";
import headersReducer from './slices/headers.slice'; // Import the slice

const appStore = configureStore({
    reducer: {
        login: loginReducer,
        search: searchReducer,
        notifications: notificationReducer,
        desktopApps: desktopAppsReducer,
        tickets: ticketsCvaReducer,
        headers: headersReducer,
    }
});

export default appStore;