import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import Settings from './pages/settings.page';
import AdminPage from './pages/admin-page/admin.page';

export const AdminRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<AdminLayout />}>
                <Route index element={<Settings />} />
                <Route path="/settings" element={<Settings />} />  
                <Route path="/admin-page" element={<AdminPage />} />  
            </Route>
        </Routes>
    );
};

const AdminLayout: React.FC = () => {
    return (
        <div>
            <Outlet />
        </div>
    );
};

