import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';  // Import a spinner from react-spinners
import './Settings.css';
import { useTranslation } from 'react-i18next';

const Settings: React.FC = () => {
    const [selectedLanguage, setSelectedLanguage] = useState<string>('English');
    const [loading, setLoading] = useState<boolean>(false);
    const { t: translator, i18n } = useTranslation();

    const languages = {
        "en": "English",
        "es": "Español",
        "ja": "日本語",
        "fr": "Français"
    } as const;

    type LanguageCode = keyof typeof languages; // This will be 'en' | 'es' | 'ja'

    const languageChange = (language: LanguageCode) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false)
            setSelectedLanguage(languages[language]);
            i18n.changeLanguage(language);
        }, 1000); // Simulate a loading time
    };

    return (
        <>
            <div className="setting_main_div">

                <div className="title_setting_1">
                    <div className="setting_title_img">
                        <div className="setting_logo">
                            <img src="/ui/static/img/desktop/Settings.png" alt="Setting" />
                        </div>
                        <div className="setting_title">{translator('settings')}</div>
                    </div>
                </div>

                <div className="settings_block">
                    <div className="settings_general">
                        <p style={{ color: 'black', fontWeight: 600 }}>{translator('generalInformation')}</p>
                        <p>{translator('currentLanguage')} : {selectedLanguage}</p>
                        <p style={{ color: 'black', fontWeight: 600 }}>{translator('cvaUptoDate')}</p>
                        <p style={{ color: 'black' }}>{translator('version2.0')}</p>
                    </div>

                    <div className="settings_maintext">
                        <div className="settings_dropdown pointerCursor">
                            <div className='settings_change_language'>
                                {translator('changeLangauge')}
                            </div>
                            <div className="settings_menu pointerCursor">
                                <div className="button" id="option1" onClick={() => languageChange('en')}>{translator('english')}</div>
                                <hr style={{ border: '0.5px solid #c1c1c1' }} />
                                <div className="button" id="option2" onClick={() => languageChange('es')}>{translator('spanish')}</div>
                                <hr style={{ border: '0.5px solid #c1c1c1' }} />
                                <div className="button" id="option3" onClick={() => languageChange('ja')}>{translator('japanese')}</div>
                                <hr style={{ border: '0.5px solid #c1c1c1' }} />
                                <div className="button" id="option4" onClick={() => languageChange('fr')}>{translator('french')}</div>
                            </div>
                        </div>
                    </div>
                </div>

                {loading && (
                    <div className="spinner-container">
                        <ClipLoader color="rgb(65, 186, 255)" loading={loading} size={50} />
                        <p style={{ color: 'black' }}>{translator('loading')}...</p>
                    </div>
                )}
            </div>
        </>
    );
};


export default Settings;
